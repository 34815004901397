<template>
  <div class="statistics">
    <databox></databox>
    <div class="title">
      <span></span>当日检查类型分析
    </div>

    <div class="statistics-map">
      <div class="leo-map" ref="leo-three"></div>
    </div>
  </div>
</template>

<script>
import Databox from "./Databox";
export default {
  name: "Statistics3",
  components: { Databox },
  data() {
    return {
      myChart: null,
      mapData: []
    };
  },
  computed: {
    indexData() {
      return this.$store.state.indexData;
    }
  },
  watch: {
    indexData: {
      handler() {
        if (this.indexData.studyTotalCurrentDate)
          this.$nextTick(function() {
            this.loadData();
          });
      },
      immediate: true
    }
  },
  mounted() {},
  methods: {
    loadData() {
      this.myChart = this.$echarts.init(this.$refs["leo-three"]);
      this.myChart = this.$echarts.init(this.$refs["leo-three"]);
      let dayData = this.indexData.studyTotalCurrentDate.examineTypeDates;
      let total = 0;
      let legend = [];
      let sData = [];
      let mapData = [];
      for (let i = 0; i < dayData.length; i++) {
        total += dayData[i].total;
        legend.push(dayData[i].examineType);
        sData.push(dayData[i].total);
        mapData.push({ value: dayData[i].total, name: dayData[i].examineType });
      }
      this.mapData = mapData;
      let options = {
        title: {
          // 显示总数的
          show: true,
          //text: "总量", // 当前写死
          //subtext: total, // 当前写死
          top: "39%",
          left: "18%",
          textAlign: "center",
          textStyle: {
            fontSize: "12",
            color: "#999"
          },
          subtextStyle: {
            fontSize: "18",
            color: "#fff"
          }
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)"
        },
        grid: {
          bottom: "0%",
      
        },
        legend: {
          orient: "vertical",
          //selectedMode:false,//取消图例上的点击事件
          align: "left",
          itemGap: 8,
          itemWidth: 8,
          itemHeight: 8,
          padding: [0, 0, 0, 0] ,
          icon: "circle",
          orient: "vertical",
          left: "40%",
          top: "6%",
          //bottom: "50%",
          data: legend,
          textStyle: {
            color: "#FFF",
            width: "",
            rich: ""
          },
          formatter: function(name) {
            // 这个mapData = this.mapData  如果直接使用 this.mapData会报错，DOM没有加载完,后面在考虑怎么优化这个问题吧，
            // 实在不行，把 legend.show = false  自己写这个样式，为了偷懒，直接使用插件自带的样式了，和设计图有点不一样，到时候再看吧
            let str = "";
            mapData.forEach(d => {
              if (d.name === name) {
                str = name + " " + d.value;
              }
            });
            return str;
          }
        },
        color: [
          "#8cc540",
          "#019fa0",
          "#009f5d",
          "#019fde",
          "#ff1244",
          "#007cdc",
          "#887ddd",
          "#cd7bdd",
          "#ff5675",
          "#b0ff00",
          "#ff8345",
          "#f8bd0b",
          "#d1d2d4",
          "#862b21"
        ],
        series: [
          {
            name: "当日检查类型分析",
            type: "pie",
            radius: ["48%", "56%"],
            center: ["20%", "52%"], // 调整位置 左 和 上
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: "center"
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: "30",
                  fontWeight: "bold"
                }
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            data: this.mapData
          }
        ]
      };
      this.myChart.setOption(options);

      // 绑定鼠标移入移出事件
      this.myChart.off("mouseover");
      this.myChart.off("mouseout");

      this.myChart.on("mouseover", () => {
        options.title.show = false;
        this.myChart.setOption(options);
      });

      this.myChart.on("mouseout", () => {
        options.title.show = true;
        this.myChart.setOption(options);
      });
    }
  }
};
</script>

<style scoped lang="scss">
.statistics {
  width: 480px;
  height: 330px;
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
  opacity: 0.8;

  .title {
    width: 100%;
    height: 28px;
    line-height: 28px;
    margin-top: 36px;
    color: #00eaff;
    padding-left: 40px;
    font-size: 20px;
    font-weight: 550;
    letter-spacing: 3px;
    span {
      display: block;
      float: left;
      width: 10px;
      height: 10px;
      background: #00eaff;
      margin-right: 10px;
      margin-top: 7px;
    }
  }

  .leo-map {
    height: 250px;
    /* 不加这个，4个直角的组件会影响到效果 */
    position: relative;
    z-index: 9999;
    padding: 0 20px 0 20px;
  }
}
.statistics::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 199%;
  height: 199%;
  border: 1px solid #61d2f7;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: scale(0.5, 0.5);
  transform: scale(0.5, 0.5);
  z-index: 1;
}
</style>
