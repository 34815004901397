export const globalUrl = () => {
  let hostname = window.location.hostname;
  let protocol = window.location.protocol;
  switch(hostname){
    case 'dt.pacs.lrhealth.com':
      return `${protocol}//service.pacs.lrhealth.com`;
    case 'dashboard.image.cnhealth.com':
      return `${protocol}//image.cnhealth.com`;
    default:
      return `${protocol}//${hostname}:9011`;
  }
}