<template>
  <div class="StatisticsTotal" v-if="indexData.studyTotalHistory">
    <databox></databox>
    <div class="title">
      <span></span>数据汇总统计
      <div class="echart-button" @click="tendencys()">
        <img src="../assets/images/duibi.png" style="position: relative;top: 1px;left: 1px;" alt />
        <!-- <i class="el-icon-copy-document" ></i>-->
        统计
      </div>
    </div>

    <div class="reveal">
      <div class="reveal-item">
        <div class="reveal-title">检查量</div>
        <div class="reveal-num">
          {{indexData.studyTotalHistory.studyTotal}}
          <span>次</span>
        </div>
      </div>
      <div class="reveal-item"></div>
    </div>

    <div class="reveal">
      <div class="reveal-item">
        <div class="reveal-title">报告数量</div>
        <div class="reveal-num">
          {{indexData.studyTotalHistory.reportTotal}}
          <span>份</span>
        </div>
      </div>
      <div class="reveal-item">
        <div class="reveal-title">患者人数</div>
        <div class="reveal-num">
          {{indexData.studyTotalHistory.userCount}}
          <span>人</span>
        </div>
      </div>
    </div>

    <div class="reveal-total">
      <div class="reveal-total-item">
        <div class="reveal-total-line">
          <span>阳</span>
          <el-progress
            v-if="yangsData"
            :percentage="yangsData"
            :color="yang"
            :stroke-width="2"
            :text-inside="true"
          ></el-progress>
          <div class="reveal-total-num" style="color: #FFD600;">{{jisuan(yangsData)}}%</div>
        </div>
        <div class="reveal-total-line">
          <span>阴</span>
          <el-progress
            v-if="yinsData"
            :percentage="yinsData"
            :color="yin"
            :stroke-width="2"
            :text-inside="true"
          ></el-progress>
          <div class="reveal-total-num" style="color: #00EAFF;">{{jisuan(yinsData)}}%</div>
        </div>
        <div class="reveal-total-line">
          <span>未知</span>
          <el-progress
            v-if="weiszhiData"
            :percentage="weiszhiData"
            :color="weizhi"
            :stroke-width="2"
            :text-inside="true"
          ></el-progress>
          <div class="reveal-total-num" style="color: #C70FFF;">{{jisuan(weiszhiData)}}%</div>
        </div>
      </div>

      <div class="reveal-total-item">
        <div class="reveal-total-line">
          <span>男</span>
          <el-progress
            v-if="nansData"
            :percentage="nansData"
            :color="yang"
            :stroke-width="2"
            :text-inside="true"
          ></el-progress>
          <div class="reveal-total-num" style="color: #FFD600;">{{jisuan(nansData)}}%</div> 
        </div>
        <div class="reveal-total-line">
          <span>女</span>
          <el-progress
            v-if="nvsData"
            :percentage="nvsData"
            :color="yin"
            :stroke-width="2"
            :text-inside="true"
          ></el-progress>
          <div class="reveal-total-num" style="color: #00EAFF;">{{jisuan(nvsData)}}%</div>
        </div>
        <div class="reveal-total-line">
          <span>未知</span>
          <el-progress
            v-if="weisszhisData"
            :percentage="weisszhisData"
            :color="weizhi"
            :stroke-width="2"
            :text-inside="true"
          ></el-progress>
          <div class="reveal-total-num" style="color: #C70FFF;">{{jisuan(weisszhisData)}}%</div>
        </div>
      </div>
    </div>
    <!-- 弹窗 -->
    <Pillar v-if="dialogTableVisibles" ref="pillar" />
  </div>
</template>

<script>
import Databox from "./Databox";
import Pillar from "../components/Pillar";
export default {
  name: "StatisticsTotal",
  components: { Databox, Pillar },
  data() {
    return {
      yin: "#12FFFE",
      yang: "#FFD600",
      weizhi: "#C70FFF",
      //对比弹出框num: 0,
      dialogTableVisibles: false,
      /* 折现图 */
      pillarChart: null
    };
  },
  computed: {
    indexData() {
      return this.$store.state.indexData;
    },
    //阴阳未知
    yangsData() {
      if (this.indexData) {
        if (this.indexData.studyTotalHistory.studyTotal == 0) {
          return 0.00001;
        }
        let a =
          (this.indexData.studyTotalHistory.mas1Count /
            this.indexData.studyTotalHistory.studyTotal || 0) * 100;
        return a == 0 ? 0.00001 : a;
      }
    },
    yinsData() {
      if (this.indexData) {
        if (this.indexData.studyTotalHistory.studyTotal == 0) {
          return 0.00001;
        }
        let a =
          (this.indexData.studyTotalHistory.mas0Count /
            this.indexData.studyTotalHistory.studyTotal || 0) * 100;
        return a == 0 ? 0.00001 : a;
      }
    },
    weiszhiData() {
      if (this.indexData) {
        if (this.indexData.studyTotalHistory.studyTotal == 0) {
          return 0.00001;
        }
        let a =
          ((this.indexData.studyTotalHistory.studyTotal -
            this.indexData.studyTotalHistory.mas1Count -
            this.indexData.studyTotalHistory.mas0Count) /
            this.indexData.studyTotalHistory.studyTotal || 0) * 100;
        return a == 0 ? 0.00001 : a;
      }
    },
    //男女未知
    nansData() {
      if (this.indexData) {
        if (this.indexData.studyTotalHistory.studyTotal == 0) {
          return 0.00001;
        }
        let a =
          (this.indexData.studyTotalHistory.sex1Count /
            this.indexData.studyTotalHistory.studyTotal || 0) * 100;
        return a == 0 ? 0.00001 : a;
      }
    },
    nvsData() {
      if (this.indexData) {
        if (this.indexData.studyTotalHistory.studyTotal == 0) {
          return 0.00001;
        }
        let a =
          (this.indexData.studyTotalHistory.sex0Count /
            this.indexData.studyTotalHistory.studyTotal || 0) * 100;
        return a == 0 ? 0.00001 : a;
      }
    },

    weisszhisData() {
      if (this.indexData) {
        if (this.indexData.studyTotalHistory.studyTotal == 0) {
          return 0.00001;
        }
        let a =(
          ((this.indexData.studyTotalHistory.studyTotal -
            this.indexData.studyTotalHistory.sex0Count -
            this.indexData.studyTotalHistory.sex1Count) /
            this.indexData.studyTotalHistory.studyTotal || 0) * 100);
        return a == 0 ? 0.00001 : a;
      }
    }
  },

  methods: {
    // 对比弹出框
    tendencys() {
      /* alert(1212) */
      this.dialogTableVisibles = true;
      this.$nextTick(() => {
        // 父组件调用子组件的方法
        this.$refs.pillar.init();
      });
    },
    jisuan(val) {
      let valF = parseFloat(val);
      return valF.toFixed(2);
    }
  }
};
</script>

<style scoped lang="scss">
.StatisticsTotal {
  width: 480px;
  height: 330px;
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
  /*  opacity: 0.8; */

  .title {
    width: 100%;
    height: 28px;
    line-height: 28px;
    margin-top: 36px;
    color: #00eaff;
    padding-left: 40px;
    font-size: 20px;
    font-weight: 550;
    letter-spacing: 3px;

    span {
      display: block;
      float: left;
      width: 10px;
      height: 10px;
      background: #00eaff;
      margin-right: 10px;
      margin-top: 7px;
    }

    .echart-button {
      float: right;
      width: 70px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border-radius: 1px;
      border: 1px solid rgba(18, 255, 254, 1);
      color: #00eaff;
      font-size: 14px;
      margin-right: 20px;
      position: relative;
      z-index: 2;
      cursor: pointer;

      i {
        margin-right: 3px;
      }
    }
  }

  .reveal {
    display: flex;
    margin-top: 20px;

    .reveal-item {
      flex: 1;

      .reveal-title {
        height: 17px;
        line-height: 17px;
        padding-left: 40px;
        font-size: 12px;
      }

      .reveal-num {
        width: 181px;
        height: 56%;
        line-height: 37px;
        margin-left: 31px;
        margin-top: 9px;
        background: #03253c;
        font-size: 24px;
        padding-left: 9px;
        border-radius: 1px;
        letter-spacing: 2px;

        span {
          font-size: 12px;
          display: inline-block;
          margin-left: 5px;
        }
      }
    }
  }

  .reveal-total {
    display: flex;
    margin-top: 20px;
    margin-top: 20px;

    .reveal-total-item {
      flex: 1;

      .reveal-total-line {
        width: 100%;
        overflow: hidden; /* 不加这个撑不起来高度 */
        padding-left: 40px;
        display: block;

        span {
          display: block;
          float: left;
          width: 40px;
          height: 17px;
          line-height: 17px;
          /*  font-size: 12px; */
          margin-right: 5px;
          transform: scale(0.9);
        }

        .el-progress {
          width: 50px;
          float: left;
        }

        .reveal-total-num {
          float: left;
          padding-left: 5px;
          font-size: 12px;
          transform: scale(0.9);
        }
      }
    }
  }
}
.StatisticsTotal::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 199%;
  height: 199%;
  border: 1px solid #61d2f7;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: scale(0.5, 0.5);
  transform: scale(0.5, 0.5);
  z-index: 1;
}
</style>
