<template>
  <!-- 对比弹出框 -->

  <el-dialog
    :visible.sync="dialogTableVisibles"
    class="pillarChart"
    @close="$emit('update:show', false)"
  >
    <div class="searchBox">
      <div class="searchBox-item" @click="selectType1(10)" :class="{active:active == 10}">检查量对比</div>
      <div class="searchBox-item" @click="selectType2(20)" :class="{active:active == 20}">文件大小</div>
    </div>

    <!-- 这里面写一个时间的搜索框 -->
    <div class="date-picker">
      <button class="filter-dy" id="filter-dy" @click="Print">
        <img style="width: 17px;" src="../assets/images/dayin.png" alt />
      </button>
      <el-date-picker
        v-model="timeData"
        type="daterange"
        size="mini"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd"
        class="data-pick"
         @change="handleFilter"
        unlink-panels
      ></el-date-picker>
      <!-- <button class="filter-search" @click="handleFilter">
        <span class="el-icon-search" ></span>
      </button> -->
    </div>

    <!-- 图表 -->
    <div class="leo-five" id="pillarChart" ref="pillarChart"></div>
  </el-dialog>
</template>

<script>
import FileSaver from "file-saver";

export default {
  name: "Pillar",
  data() {
    return {
      dialogTableVisibles: false, // 弹框显示
      pillarChart: null,
      active: 10,
      tableDate: {
        xData: [],
        sData: []
      },
      /* 获取过去时间，并传值给现在时间 */
      getPassYearFormatDate() {
        var nowDate = new Date();
        var date = new Date(nowDate);
        date.setDate(date.getDate() - 365);
        var seperator1 = "-";
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var strDate = date.getDate();
        if (month >= 1 && month <= 9) {
          month = "0" + month;
        }
        if (strDate >= 0 && strDate <= 9) {
          strDate = "0" + strDate;
        }
        var formatDate = year + seperator1 + month + seperator1 + strDate;
        this.getNowFormatDate(formatDate);
      },
      /* 获取现在时间，并接受过去时间的值 */
      getNowFormatDate(formatDate) {
        var date = new Date();
        var seperator1 = "-";
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var strDate = date.getDate();
        if (month >= 1 && month <= 9) {
          month = "0" + month;
        }
        if (strDate >= 0 && strDate <= 9) {
          strDate = "0" + strDate;
        }
        var nowData = year + seperator1 + month + seperator1 + strDate;
        this.timeData = [formatDate, nowData]; // 默认赋值一年时间
      },

      //时间
      timeData: [],
      listQuery: {
        info: {
          startDate: "",
          endDate: ""
        }
      },
      hospitalNameList: []
    };
  },
  mounted() {
    this.getPassYearFormatDate();
  },

  computed: {
    motheData() {
      return this.$store.state.motheData;
    },
    chinaMapData() {
      return this.$store.state.chinaMapData;
    }
  },
  watch: {
    dialogTableVisibles(newVal, oldVal) {
      if (!newVal) {
        document.title = this.$titles;
      }

      this.getPassYearFormatDate();
    },
    motheData: {
      handler() {
        if (this.motheData) this.$nextTick(function() {});
      },
      immediate: true
    }
  },
  methods: {
    init() {
      this.dialogTableVisibles = true;
      setTimeout(() => {
        if (this.dialogTableVisibles) {
          this.selectType1(10);
          this.drawLine();
        }
      }, 800);
    },
    drawLine() {
      // 在 el-dialog 里面只能使用 原生JS获取DOM
      this.pillarChart = this.$echarts.init(
        document.getElementById("pillarChart")
      );
      //初始化数据
      var options = {
        tooltip: {
          show: true,
          trigger: "axis",
          backgroundColor: "rgba(255,255,255,0.8)",
          extraCssText: "box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);",
           formatter: function(params) {
            let string = '检查量对比：' +  params[0].value 
            return string;
          },
          textStyle: {
            color: "#6a717b",
          },
          // 相对位置
          position: ["50%", "30%"]
        },
        grid: {
          left: "3%",
          right: "6%",
          bottom: "10%",
          containLabel: true
        },
        yAxis: [
          {
            type: "category",
            splitNumber: 15,
            data: this.hospitalNameList,
            inverse: true,
            //max:4,
            //splitNumber: 3,
            axisTick: {
              alignWithLabel: true
            },
            axisLabel: {
              interval: 0, //代表显示所有x轴标签显示
              fontSize: 16,
              fontWeight: 500,
              textStyle: {
                color: '#929ea0'//"#E5E5E7"
              }
            },
            axisLine: {
              lineStyle: {
                color: "#073B47"
              }
            }
          }
        ],
        xAxis: [
          {
            type: "value",
            //splitNumber: 3,
            data: ["1", "1", "1", "1", "1", "1", "1", "1", "1", "1"],
            axisLabel: {
              interval: 0, //代表显示所有x轴标签显示
              rotate: 0, //代表逆时针旋转45度
              fontSize: 16,
              fontWeight: 500,
              textStyle: {
                color: "#929ea0"
              }
            },
            axisLine: {
              lineStyle: {
                color: "#040B22"
              }
            },
            splitLine: {
              lineStyle: {
                color: "#073B47"
              }
            }
          }
        ],
        //backgroundColor: "#040b22",
        series: [
          {
            name: "Top 10",
            type: "bar",
            barGap: "80%" /*多个并排柱子设置柱子之间的间距*/,
            barWidth: 10,
            data: this.tableDate.xData,
            label: {
              normal: {
                show: false,
                position: "insideRight",
                textStyle: {
                  color: "white" //color of value
                }
              }
            },
            itemStyle: {
              normal: {
                color: {
                  // 柱子的颜色
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0.5,
                  y2: 0.5,
                  x3: 0,
                  y3: 1,
                  colorStops: [
                    // 柱子的颜色渐变 是个数组
                    { offset: 0, color: "rgba(0,246,255,1)" },
                    { offset: 0.5, color: "rgba(0,246,255,0.75)" },
                    { offset: 1, color: "rgba(0,234,255,0.5)" }
                  ],
                  globalCoord: false // 缺省为 false
                },
                barBorderRadius: [0, 15, 15, 0],
                shadowColor: "rgba(0,0,0,0.1)",
                shadowBlur: 3,
                shadowOffsetY: 3
              }
            }
          }
        ]
      };
      this.pillarChart.setOption(options);
    },
    drawLine2() {
      // 在 el-dialog 里面只能使用 原生JS获取DOM
      this.pillarChart = this.$echarts.init(
        document.getElementById("pillarChart")
      );
      //初始化数据
      var options = {
        tooltip: {
          show: true,
          trigger: "axis",
          backgroundColor: "rgba(255,255,255,0.8)",
          extraCssText: "box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);",
          formatter: function(params) {
           
            let string = '文件大小：' +  params[0].value + 'G'

            return string;
          },
          textStyle: {
            color: "#6a717b"
          },
          // 相对位置
          position: ["50%", "30%"]
        },
        grid: {
          left: "3%",
          right: "6%",
          bottom: "10%",
          containLabel: true
        },
        yAxis: [
          {
            type: "category",
            splitNumber: 15,
            data: this.hospitalNameList,
            inverse: true,
            //max:4,
            //splitNumber: 3,
            axisTick: {
              alignWithLabel: true
            },
            axisLabel: {
              interval: 0, //代表显示所有x轴标签显示
              fontSize: 16,
              fontWeight: 500,
              textStyle: {
                color: "#929ea0"
              }
            },
            axisLine: {
              lineStyle: {
                color: "#073B47"
              }
            }
          }
        ],
        xAxis: [
          {
            type: "value",
            //splitNumber: 3,
            data: [],
            //  data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
            axisLabel: {
              interval: 0, //代表显示所有x轴标签显示
              rotate: 0, //代表逆时针旋转45度
              fontSize: 16,
              fontWeight: 500,
              textStyle: {
                color: "#929ea0"
              }
            },
            axisLine: {
              lineStyle: {
                color: "#040B22"
              }
            },
            splitLine: {
              lineStyle: {
                color: "#073B47"
              }
            }
          }
        ],
        //backgroundColor: "#040b22",
        series: [
          {
            name: "对比：",
            type: "bar",
            barGap: "80%" /*多个并排柱子设置柱子之间的间距*/,
            barWidth: 10,
            data: this.tableDate.xData,
            label: {
              normal: {
                show: false,
                position: "insideRight",
                textStyle: {
                  color: "white" //color of value
                }
              }
            },
            itemStyle: {
              normal: {
                color: {
                  // 柱子的颜色
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0.5,
                  y2: 0.5,
                  x3: 0,
                  y3: 1,
                  colorStops: [
                    // 柱子的颜色渐变 是个数组
                    { offset: 0, color: "rgba(0,246,255,1)" },
                    { offset: 0.5, color: "rgba(0,246,255,0.75)" },
                    { offset: 1, color: "rgba(0,234,255,0.5)" }
                  ],
                  globalCoord: false // 缺省为 false
                },
                barBorderRadius: [0, 15, 15, 0],
                shadowColor: "rgba(0,0,0,0.1)",
                shadowBlur: 3,
                shadowOffsetY: 3
              }
            }
          }
        ]
      };
      this.pillarChart.setOption(options);
    },
    // 检查量对比
    selectType1(type) {
      this.active = type;
      //医院详情数据
      let motheDeils = this.chinaMapData;
      //const mothecodelist = _dataes.length > 0 ? _dataes.join(",") : "";
      let code;
      if (this.$store.state.mapLevel == 1) {
        code = this.$getCode(this.$store.state.HospitalCode);
      } else {
        code = this.$getCode(this.$store.state.currentHospital);
      }
      code = code ? code : this.$getCode(this.$store.state.HospitalCode);
      this.axios
        .get("/stat/queryStudyTotalByHospitalCode.service", {
          params: {
            // currentDate: this.$moment(new Date()).format('YYYY-MM-DD'),
            startDate: this.$moment(this.timeData[0]).format("YYYYMMDD"),
            endDate: this.$moment(this.timeData[1]).format("YYYYMMDD"),
            hospitalCodeList: code
          }
        })
        .then(result => {
          if (result.success) {
            this.hospitalNameList = [];
            this.tableDate.xData = [];
            if (this.$store.state.chinaMapData.length > 0) {
              result.data.forEach(d => {
                const item = this.$store.state.chinaMapData.filter(dd => {
                  return dd.code === d.hospitalCode;
                })[0];
                this.tableDate.xData.push(d.studyTotal);
                this.hospitalNameList.push(item.name);
              });
            }
            this.drawLine();
          }
        });
    },
    //点击搜索
    handleFilter() {
      this.active === 10 ? this.selectType1(10) : this.selectType2(20);
    },
    //打印图表
    Print() {
      document.title =
        this.active == "10"
          ? `${this.$titles}(检查量对比)`
          : `${this.$titles}(文件大小对比)`; // 默认是第一个
      this.$print(document.getElementById("pillarChart"));
      //this.$print();
    },
    getSizeString(size) {
      if (!size) size = 0;
      if (0 <= size && size < 1024) return size.toFixed(2) + "M";
      else if (1024 <= size && size < 1024 * 1024)
        return (size / 1024).toFixed(2) + "G";
      else if (1024 * 1024 < size)
        return (size / (1024 * 1024)).toFixed(2) + "T";
    },
    // 文件大小
    selectType2(type) {
      this.active = type;
      //医院详情数据
      let motheDeils = this.chinaMapData;
      let code;
      if (this.$store.state.mapLevel == 1) {
        code = this.$getCode(this.$store.state.HospitalCode);
      } else {
        code = this.$getCode(this.$store.state.currentHospital);
      }
      code = code ? code : this.$getCode(this.$store.state.HospitalCode);
      this.axios
        .get("/stat/queryFileSizeByHospitalCode.service", {
          params: {
            // currentDate: this.$moment(new Date()).format('YYYY-MM-DD'),
            startDate: this.$moment(this.timeData[0]).format("YYYYMMDD"),
            endDate: this.$moment(this.timeData[1]).format("YYYYMMDD"),
            hospitalCodeList: code
          }
        })
        .then(result => {
          if (result.success) {
            this.hospitalNameList = [];
            this.tableDate = result.data;
            if (this.$store.state.chinaMapData.length > 0) {
              result.data.forEach(d => {
                const item = this.$store.state.chinaMapData.filter(dd => {
                  return dd.code === d.hospitalCode;
                })[0].name;
                this.hospitalNameList.push(item);
              });
            }
            let xData = [];
            let sData = [];
            for (let i = 0; i < this.tableDate.length; i++) {
              let e = this.tableDate[i];
              //e.fileSizTotal=this.getSizeString(e.fileSizTotal)/1024/1024,
              //e.fileSizTotal = this.getSizeString(e.fileSizTotal / 1024 / 1024 / 1024),
              let _value = ((e.fileSizTotal * 3) / 1024 / 1024 / 1024).toFixed(2)
              xData.push(_value);

              // xData.push((e.fileSizTotal));
              sData.push(e.hospitalCode);
            }

            this.tableDate = { xData, sData };
            pillarChart.removeAttribute("_echarts_instance_");
            this.drawLine2();
          }
        });
    }
  }
};
</script>

<style lang="scss">
// 组件样式调整
.pillarChart {
  height: 6rem;

  .el-dialog {
    background-color: rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 1px rgba(54, 241, 242, 0.3);
  }

  .el-dialog__header {
    padding: 0;
    height: 0;
    background: #122c3a;
  }

  .el-dialog__body {
    padding: 0;
    background: #040b22;
  }

  .searchBox {
    display: flex;
    background: #122c3a;
    height: 50px;
    line-height: 50px;
    font-size: 12px;

    .searchBox-item {
      width: 140px;
      cursor: pointer;
      text-align: center;
      height: 49px;
      box-shadow: 0 0 0 1px rgba(54, 241, 242, 0.2);
      color: #fff;
    }

    .active {
      background: #040b22;
      color: #38ffff;
    }
  }

  .date-picker {
    padding: 35px 0 10px 40px;

    .el-input--prefix .el-input__inner {
      color: #00eaff !important;
    }
  }

  .leo-five {
    height: 580px;
  }
}
.el-range-editor--mini.el-input__inner {
  background: #081e33;
}
.el-range-editor--mini .el-range-input {
  font-size: 12px;
  background: #081e33;
  color: #01eaff;
}
.el-date-table td.in-range div,
.el-date-table td.in-range div:hover,
.el-date-table.is-week-mode .el-date-table__row.current div,
.el-date-table.is-week-mode .el-date-table__row:hover div {
  background-color: #096082;
}
.el-date-table td.today.end-date span,
.el-date-table td.today.start-date span {
  color: #fff;
  background: #c70fff;
}
.el-date-table td.end-date span,
.el-date-table td.start-date span {
  background-color: #c70fff;
}
.el-input__inner {
  border: none;
}
</style>
