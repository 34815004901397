<template>
  <div style="position: relative;">
    <div style="position:absolute ;top: 85%;left: 3%;">
      <p style="float: right;margin-left: 10px;opacity: 0.8;">全部医院</p>
      <img src="../../../assets/images/hosp.png" alt />
      <p style="position: relative;left: 40px;top: -16px;font-size: 12px;opacity: 0.8;">
        <span style="font-size: 18px;margin-right: 5px;">{{this.hospcount}}</span>家
      </p>
    </div>
    <div class="china-map" :style="`opacity:${opacity}`">
      <div class="leo-map" ref="leo-map"></div>
    </div>
  </div>
</template>

<script>
import cityMap from "../../../../public/chinaMap/citymap";
import provincesInfo from "../../../../public/chinaMap/provincesInfo";
import chinaMap from "../../../../public/chinaMap/china";
import { mapState, mapGetters } from "vuex";
import VueEvent from "../../../utils/VueEvent";
export default {
  name: "ChinaMap",
  props: ["selectedHospital"],
  data() {
    return {
      special: ["北京", "天津", "上海", "重庆", "香港", "澳门"],
      myChart: null,
      seriesName: "china", // 这个是为了区分直辖市的，当时看了半天才看懂 2019/12/26 1:31
      geoCoordMap: {},
      echartsOptions: {},
      hospitals: {},
      history: [],
      sData: [],
      showAAAA: false,
      hospcount: "", //医院总数统计
      opacity:0,
    };
  },
  mounted() {
    // console.log('chinamap')
    // VueEvent.$on("test456", () => {
    //   this.$nextTick(function() {
    //     this.loadMap();
    //   });
    // });
     VueEvent.$on("handleSelectHospital", (data) => {
      this.$nextTick(function() {
        this.selectedHospital = data
      });
    });
  },
  computed: {
    ...mapState(["chinaMapData", "handleBack", "currentHospital"]),
  },
  watch: {
    opacity:{
      immediate:true,
      handler(v){
        // console.log(v)
      }
    },
    chinaMapData: {
      immediate: true,
      handler: function(v) {
        // console.log(v)
       this.$nextTick(()=>{
         if(!this.opacity){
           this.loadMap(true, () => {
               this.gotoMyProvince();
           });
         }
       })
      }
    
    },
    selectedHospital: {
      immediate: true,
      handler(v) {
        if (!v.city) return false;
        // 个别县级城市 因为ID问题会报错，主要是海南
        let city = v.city;
        let province = v.province;
        let provinceUrl = `/chinaMap/province/${provincesInfo[province]}.json`;
        let url = `/chinaMap/city/${cityMap[v.city]}.json`;
        this.getData(
          url,
          {
            name: v.city
          },
          this.echartsOptions
        );

        this.history = {
          province: {
            url: provinceUrl,
            res: {
              name: province
            }
          },
          city: {
            url,
            res: {
              name: city
            }
          },
          level: 3
        };
        this.$store.commit("setMapLevel", 3);
      }
    },
    handleBack: {
      immediate: true,
      handler(v) {
        if (this.history.level === 1) {
          this.myChart.dispose();
          this.loadMap(false);
          this.$echarts.registerMap("china", chinaMap);
          this.echartsOptions.geo.map = "china";
          this.myChart.setOption(this.echartsOptions);
          this.$store.commit("setMapLevel", 1);
        } else if (this.history.level === 2) {
          this.myChart.dispose();
          this.loadMap(false);
          this.$echarts.registerMap("china", chinaMap);
          this.echartsOptions.geo.map = "china";
          this.myChart.setOption(this.echartsOptions);
          this.$store.commit("setMapLevel", 1);
          this.getAreaHospitals({
            type: "china",
            name: ""
          });
        } else if (this.history.level === 3) {
          this.getData(
            this.history.province.url,
            this.history.province.res,
            this.echartsOptions
          );
          this.history.level = 2;
          this.$store.commit("setMapLevel", 2);
          this.getAreaHospitals({
            type: "province",
            name: this.history.province.res.name
          });
        }
      }
    }
  },
  methods: {
    loadMap(init, callback) {
      if(!this.chinaMapData) return false
      this.myChart = this.$echarts.init(this.$refs["leo-map"]);
      if (init) {
        this.$echarts.registerMap("china", chinaMap);
      }
      // console.log(this.myChart);
      let regionsSet = new Set();
      let regions = [];
      let sData = [];
      let geoCoordMap = []; //经纬度
      for (let index = 0; index < this.chinaMapData.length; index++) {
        const element = this.chinaMapData[index];
        this.hospcount = this.chinaMapData.length;
        sData.push({
          time: element.gmtCreate,
          name: element.name,
          value: 9
        });
        geoCoordMap[element.name] = [element.longitude, element.latitude];
        this.hospitals[element.name] = element;
        if (!regionsSet.has(element.province)) {
          regionsSet.add(element.province);
          regions.push({
            time: element.gmtCreate,
            name: element.province,
            itemStyle: {
              areaColor: "#00EAFF",
              color: "red"
            }
          });
        }
        if (!regionsSet.has(element.city)) {
          regionsSet.add(element.city);
          regions.push({
            name: `${element.city}`,
            itemStyle: {
              areaColor: "#00EAFF",
              color: "red"
            }
          });
        }
      }
      this.geoCoordMap = geoCoordMap;
      sData = this.convertData(sData);
      this.sData = sData;
      let options = {
        tooltip: {
          padding: 3,
          backgroundColor: "rgba(	0,0,0,0.6)",
          borderColor: "#777",
          textStyle: {
            color: "white", //省份字体颜色
            fontSize: 10
          },
          formatter: function(a) {
            //return `${a.name}:${a.value[2]}`;
            return `${a.name}-接入时间：${a.data.time}`;
          }
        },
        geo: {
          zoom: 1, //缩放级别
          map: "china", // 表示中国地图
          roam: true, // 禁止移动地图
          label: {
            normal: {
              show: true, // 是否显示对应地名
              textStyle: {
                color: "white", //省份字体颜色
                fontSize: 10
              }
            }
          },
          regions,
          itemStyle: {
            normal: {
              areaColor: "rgba(6,9,25,0.6)", // 地图背景色
              borderColor: "rgba(0,234,255,0.8)", // 省份直接的边框颜色
              borderWidth: 1.2
            },
            emphasis: {
              areaColor: "#00EAFF", // 选中时候的颜色
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowBlur: 10,
              borderWidth: 0,
              shadowColor: "rgba(0,234,255,1)"
            }
          }
        },
        series: [
          {
            type: "scatter",
            data: sData,
            coordinateSystem: "geo", // 对应上方配置 //使用地理坐标系，通过 geoIndex 指定相应的地理坐标系组件。
            symbolSize: 6
          }
        ]
      };
      this.echartsOptions = options;
      // 渲染地图
      this.myChart.setOption(options);

      //  绑定点击事件
      this.myChart.off("click");
      this.myChart.on("click", res => {
        // 坐标点点击事件
        if (res.componentSubType === "scatter") {
          let city = this.hospitals[res.name].city;
          let province = this.hospitals[res.name].province;
          let url = `/chinaMap/city/${cityMap[city]}.json`;
          //let url = `/chinaMap/city/${cityMap[city]}.json`;
          let provinceUrl = `/chinaMap/province/${provincesInfo[province]}.json`;
          this.getData(url, res, options);
          this.$store.commit("setCurrentHospital", this.hospitals[res.name]);
          //console.log(this.$store.state.currentHospital,777)
          VueEvent.$emit("test123", this.$store.state.currentHospital.code);
          this.history = {
            province: {
              url: provinceUrl,
              res: {
                name: province
              }
            },
            city: {
              url,
              res
            },
            level: 3
          };
          this.$store.commit("setMapLevel", 3);
        } else {
          if (!regionsSet.has(res.name)) {
            return false;
          }
          //省级点击事件
          if (res.name in provincesInfo) {
            //如果点击的是34个省、市、自治区，绘制选中地区的二级地图
            let url = `/chinaMap/province/${provincesInfo[res.name]}.json`;
            this.getData(url, res, options);
            this.history.province = {
              url,
              res
            };
            this.history.level = 2;
            this.$store.commit("setMapLevel", 2);
            this.getAreaHospitals({
              type: "province",
              name: res.name
            });
          } else if (this.seriesName in provincesInfo) {
            if (this.special.indexOf(this.seriesName) >= 0) {
              // 这一步相当于初始化数据1
              this.$echarts.registerMap("china", chinaMap);
              options.geo.map = "china";
              this.myChart.setOption(options);
              this.history.level = 1;
              this.$store.commit("setMapLevel", 1);
            } else {
              // 显示县级地图
              if (cityMap[res.name]) {
                // 个别县级城市 因为ID问题会报错，主要是海南
                let url = `/chinaMap/city/${cityMap[res.name]}.json`;
                this.getData(url, res, options);
                this.history.city = {
                  url,
                  res
                };
                this.history.level = 3;
                this.$store.commit("setMapLevel", 3);
                this.getAreaHospitals({
                  type: "city",
                  name: res.name
                });
              }
            }
          } else {
            // 这一步相当于初始化数据2
            this.$echarts.registerMap("china", chinaMap);
            options.geo.map = "china";
            this.myChart.setOption(options);
            this.history.level = 1;
            this.$store.commit("setMapLevel", 1);
          }
        }
      });
      if (callback instanceof Function) {
        callback();
      }
    },
    convertData(data) {
      var res = [];
      for (var i = 0; i < data.length; i++) {
        var geoCoord = this.geoCoordMap[data[i].name];
        if (geoCoord) {
          res.push({
            name: data[i].name,
            time: data[i].time,
            value: geoCoord.concat(data[i].value),
            itemStyle: {
              color: "yellow"
            }
          });
        }
      }
      return res;
    },
    gotoMyProvince(callback) {
      //切换得时候判断是否配置
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      if(userInfo.provinceName == null){
        this.opacity = 1
      }
      this.myChart.trigger("click", {
        name: `${userInfo.provinceName}`
      });
    },
    getAreaHospitals(obj) {
      let res = [];
      for (let index = 0; index < this.chinaMapData.length; index++) {
        const element = this.chinaMapData[index];
        if (obj.type === "province") {
          if (element.province === obj.name) {
            res.push(element);
          }
        } else if (obj.type === "city") {
          if (element.city === obj.name) {
            res.push(element);
          }
        }
      }

      this.$store.commit("setCurrentHospital", res);
      //console.log(res, "setCurrentHospital");
      // 事件传播
      if (this.$store.state.currentHospital.length > 0) {
        //console.log(this.$store.state.currentHospital,'this.$store.state.currentHospital')
        // let code = this.$store.state.HospitalCode.join(",");
        let code = this.$store.state.currentHospital
          .map(res => {
            return res.code;
          })
          .join(",");
        VueEvent.$emit("test123", code);
      }
      return res;
    },
    getData(url, res, options) {
      // 封装一些公用的数据请求
      this.axios.get(url).then(response => {
        this.myChart.dispose();
        this.loadMap(false);
        let data = response;
        this.$echarts.registerMap(res.name, data);
        options.geo.map = res.name;
        this.seriesName = res.name;
        //动态获取医院数据，避免再点下一级的时候把其他市的地标也传过去
        let sData = [];
        let currentHospital = [];
        if (this.currentHospital.length) {
          currentHospital = this.currentHospital;
        } else if (this.currentHospital !== undefined) {
          currentHospital = [this.currentHospital];
        }
        // console.log(this.currentHospital)
        for (let index = 0; index < this.sData.length; index++) {
          const element = this.sData[index];
          for (let j = 0; j < currentHospital.length; j++) {
            const element2 = currentHospital[j];

            if (element.name === element2.name) {
              sData.push(element);
            }
          }
        }
        options.series[0].data = sData;
        this.myChart.setOption(options);
        this.opacity = 1;
      });
    }
  }
};
</script>

<style scoped lang="scss">
.china-map {
  width: 830px;
  height: 480px;
  margin: 0 auto;

  .leo-map {
    min-height: 480px;
    width: 830px;
    height: 480px;
  }
}
</style>
