import { render, staticRenderFns } from "./ChinaMap.vue?vue&type=template&id=137cbba9&scoped=true&"
import script from "./ChinaMap.vue?vue&type=script&lang=js&"
export * from "./ChinaMap.vue?vue&type=script&lang=js&"
import style0 from "./ChinaMap.vue?vue&type=style&index=0&id=137cbba9&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/.store/vue-loader@15.10.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "137cbba9",
  null
  
)

export default component.exports