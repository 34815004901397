import { render, staticRenderFns } from "./HomeRight.vue?vue&type=template&id=56054c76&scoped=true&"
import script from "./HomeRight.vue?vue&type=script&lang=js&"
export * from "./HomeRight.vue?vue&type=script&lang=js&"
import style0 from "./HomeRight.vue?vue&type=style&index=0&id=56054c76&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/.store/vue-loader@15.10.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56054c76",
  null
  
)

export default component.exports