<template>
    <div class="home-right">
        <StatisticsTotal/>
        <StatisticsTotal2/>
        <StatisticsTotal3/>
    </div>
</template>

<script>
    import StatisticsTotal from '@/components/StatisticsTotal'
    import StatisticsTotal2 from '@/components/StatisticsTotal2'
    import StatisticsTotal3 from '@/components/StatisticsTotal3'

    export default {
        name: "HomeRight",
        components: {StatisticsTotal, StatisticsTotal2, StatisticsTotal3}
    }
</script>

<style scoped lang="scss">
    .home-right {
        width: 480px;
        float: right;
        margin: 20px 30px 30px 20px;
        overflow: hidden !important;
    }
</style>
