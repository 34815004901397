<template>
  <div :class="{'hidden':hidden}" class="pagination-container">
    <el-pagination
            :background="background"
            :current-page.sync="currentPage"
            :page-size.sync="pageSize"
            :layout="layout"
            :page-sizes="pageSizes"
            :total="total"
            v-bind="$attrs"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
  import { scrollTo } from "@/utils/scroll-to";

  export default {
    name: "Pagination",
    props: {
      total: {
        required: true,
        type: Number
      },
      page: {
        type: Number,
        default: 1
      },
      limit: {
        type: Number,
        default: 20
      },
      pageSizes: {
        type: Array,
        default() {
          return [10, 20, 30, 50];
        }
      },
      layout: {
        type: String,
        default: "total, sizes, prev, pager, next, jumper"
      },
      background: {
        type: Boolean,
        default: true
      },
      autoScroll: {
        type: Boolean,
        default: true
      },
      hidden: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      currentPage: {
        get() {
          return this.page;
        },
        set(val) {
          this.$emit("update:page", val);
        }
      },
      pageSize: {
        get() {
          return this.limit;
        },
        set(val) {
          this.$emit("update:limit", val);
        }
      }
    },
    methods: {
      handleSizeChange(val) {
        this.$emit("pagination", { page: this.currentPage, limit: val });
        if (this.autoScroll) {
          scrollTo(0, 800);
        }
      },
      handleCurrentChange(val) {
        this.$emit("pagination", { page: val, limit: this.pageSize });
        if (this.autoScroll) {
          scrollTo(0, 800);
        }
      }
    }
  };
</script>

<style>

  .pagination-container.hidden {
    display: none;
  }
  .pagination-container{
    padding-top: 15px;
    text-align: right;
}
.el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li {
    margin: 0 5px;
    background-color: #096372;
    color: #606266;
    border-radius: 2px;
}
.el-pagination.is-background .btn-next.disabled, .el-pagination.is-background .btn-next:disabled, .el-pagination.is-background .btn-prev.disabled, .el-pagination.is-background .btn-prev:disabled, .el-pagination.is-background .el-pager li.disabled {
    color: #fff;
}

</style>

