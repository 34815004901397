<template>
    <div class="home-left">
        <Statistics/>
        <Statistics2/>
        <Statistics3/>
    </div>
</template>

<script>
    import Statistics from '@/components/Statistics'
    import Statistics2 from '@/components/Statistics2'
    import Statistics3 from '@/components/Statistics3'

    export default {
        name: "HomeLeft",
        components: {Statistics, Statistics2, Statistics3}
    }
</script>

<style scoped lang="scss">
    .home-left {
        width: 480px;
        margin: 20px 20px 30px 30px;
        float: left;
        overflow: hidden !important;
    }
</style>
