<template>
  <div class="home-center">
    <!-- header -->
    <div class="header">
      <span>
        <i v-if="titlename">{{titlename}}</i>云影像大数据平台
      </span>
    </div>

    <CenterNumber @handleSelectHospital="(item)=>{selectedHospital = item}" />
    <ChinaMap :selectedHospital="selectedHospital" />
    <div class="seamless-box">
      <div class="scrollTableHead">
        <div class="headCell" style="width: 40%;">检查医院</div>
        <div class="headCell" style="width:20%;">姓名</div>
        <div class="headCell" style="width: 25%;">检查时间</div>
        <div class="headCell" style="width: 15%;">检查类型</div>
      </div>
      <vue-seamless :data="rollData" class="seamless-warp" :class-option="optionSetting">
        <ul class="item">
          <li
            v-for="(item,key) in rollData"
            :key="key"
            style="display: flex;line-height: 35px;height: 35px;"
          >
            <span style="width: 40%;" class="spanrow" v-text="item.hospitalName"></span>
            <span style="width: 20%;" class="spanrow" v-text="item.patientName"></span>
            <span style="width: 25%;" class="spanrow" v-text="item.studyTime"></span>
            <span style="width: 15%;" class="spanrow" v-text="item.modality"></span>
          </li>
        </ul>
      </vue-seamless>
    </div>
  </div>
</template>

<script>
import ChinaMap from "./ChinaMap";
import CenterNumber from "../../../components/CenterNumber";
import vueSeamless from "vue-seamless-scroll";
import { mapGetters } from "vuex";

export default {
  name: "HomeCenter",
  components: { ChinaMap, CenterNumber, vueSeamless },
  data() {
    return {
      //标题
      titlename: "",
      //自动播放
      animate: false,
      selectedHospital: {}
    };
  },
  computed: {
    ...mapGetters(["rollData"]),
    rollData() {
      return this.$store.state.rollData;
    },
    optionSetting() {
      return {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 ，这个值最好是 数组的length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      };
    }
  },
  created() {
    this.titlename = JSON.parse(sessionStorage.getItem("userInfo")).name;
  },
};
</script>

<style scoped lang="scss">
.home-center {
  width: calc(100% - 1060px);
  float: left;
  overflow: hidden !important;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;

  .header {
    height: 85px;
    background: url("../../../assets/images/title.png") no-repeat center;
    background-size: 100% 100%;
    text-align: center;
    position: relative;
    span {
      display: inline-block;
      position: inherit;
      top: 26px;
      color: #00eaff;
      font-size: 22px;
      font-weight: 500;
    }
  }

  .seamless-box {
    width: 100%;
    padding: 0 20px 0 20px;
    text-align: center;
    opacity: 0.8;

    .scrollTableHead {
      display: flex;
      margin-bottom: 15px;
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid #073b47;
      color: #00eaff;

      .headCell {
        width: 25%;
      }
    }

    .seamless-warp {
      height: 180px;
      overflow: hidden;
    }
  }

  .box {
    width: 100%;
    height: 400px;
    border: 1px solid #61d2f7;
  }
}
</style>
