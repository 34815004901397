<template>
  <div class="home">
    <home-left></home-left>
    <home-center></home-center>
    <home-right></home-right>
  </div>
</template>
<script>
// import ChinaMap from './components/ChinaMap'
import HomeLeft from "./components/HomeLeft";
import HomeCenter from "./components/HomeCenter";
import HomeRight from "./components/HomeRight";
import { mapMutations, mapActions, mapState } from "vuex";
import VueEvent from "../../utils/VueEvent";

export default {
  components: { HomeLeft, HomeCenter, HomeRight },
  data() {
    return {
      hospitalList: [],
      formData: null
    };
  },
  created() {
    VueEvent.$on("test123", code => {
      this.emitBus(code);
    });
  },
  mounted() {
    // 监听浏览器窗口大小，设置banner的高度
    //window.addEventListener('resize', () => {
    //window.location.reload();
    //})

    // this.indexForm();
    this.indexData();
    this.$store.state.timer = setInterval(() => {
      // let code =
      //   this.$store.state.activeCode ||
      //   this.$store.state.HospitalCode.join(",")
      let code;
      if (this.$store.state.mapLevel == 1) {
        code = this.$getCode(this.$store.state.HospitalCode);
      } else {
        code = this.$getCode(this.$store.state.currentHospital);
      }
      code = code ? code : this.$getCode(this.$store.state.currentHospital);
      // 选中医院的时候刷新数据
      let params = {
        startDate: this.$store.state.flag ? this.$moment(new Date()).format("YYYYMMDD") : '',
        endDate: this.$store.state.flag ? this.$moment(new Date()).format("YYYYMMDD") :'',
        hospitalCodeList: code,
        page: {
          current: 1,
          length: 30
        }
      };
      this.$store.dispatch("loadIndexTjDate", code); //返回的主页面数据
      this.$store.dispatch("loadMotheTjDate", code); //返回的月检查量趋势
      this.$store.dispatch("loadHosData", params);
      this.$store.dispatch("loadReadForm", code);
      this.$store.dispatch("loadRollForm", code);
    },30000);
  },
  beforeDestroy() {
    if (this.timer) {
      // 如果定时器还在运行 或者直接关闭，不用判断
      clearInterval(this.$store.state.timer); //关闭
    }
  },
  computed: {
    ...mapState(["currentHospital"])
  },
  watch: {
    "currentHospital.name": {
      immediate: true,
      handler(v) {
        //console.log("currentHospital", v); //医院改变后处理
      }
    }
  },
  methods: {
    ...mapMutations([
      "updateChinaMap",
      "indexTjDate",
      "motheTjDate",
      "dayhospDate",
      "updateHosCode",
      "readnumDate",
      "rollnumDate"
    ]),
    ...mapActions(["loadHosData"]),
    /* 主页面接口 */
    indexForm(code) {
      this.axios
        .get("/stat/queryStatMain.service", {
          params: {
            // currentDate: this.$moment(new Date()).format('YYYY-MM-DD'),
            currentDate: this.$moment(new Date()).format("YYYYMMDD"), //"20200215",
            hospitalCodeList: code
          }
        })
        .then(result => {
          if (result.success) {
            this.formData = result.data;
            this.indexTjDate(this.formData);
          }
        });
    },
    /* 账号所有权限医院列表接口 */
    async indexData() {
      const result = await this.axios.get("/hospital/getLinkHospitals.service");
      try {
        if (result.success) {
          this.hospitalList = result.data;
          // 不需要导入mapMutations即可使用
          // this.$store.commit('updateChinaMap', this.hospitalList)
          // 需要导入mapMutations才可使用
          this.updateChinaMap(this.hospitalList);//返回的所有医院包括医院的经纬度
          //console.log('hospitalList', this.hospitalList)
          const _data =
            this.hospitalList.map(item => {
              // filter
              return item.code;
            }) || [];
          const code = _data.length > 0 ? _data.join(",") : "";
          this.updateHosCode(_data);

          this.emitBus(code);
        }
      } catch (error) {
        console.log(error);
      }
    },
    emitBus(code) {
      let params = {
        startDate: this.$moment(new Date()).format("YYYYMMDD"),
        endDate: this.$moment(new Date()).format("YYYYMMDD"),
        hospitalCodeList: code,
        page: {
          current: 1,
          length: 1000
        }
      };
      this.$store.dispatch("loadIndexTjDate", code);
      this.$store.dispatch("loadMotheTjDate", code);
      this.$store.dispatch("loadHosData", params);
      this.$store.dispatch("loadReadForm", code);
      this.$store.dispatch("loadRollForm", code);
    },

    /* 月检查量走势接口 */
    motheForm(code) {
      this.axios
        .get("/stat/queryStudyTotalByMonth.service", {
          params: {
            // currentDate: this.$moment(new Date()).format('YYYY-MM-DD'),
            //startDate: "20190228",
            // endDate: "20200228",
            startDate: this.$moment(
              new Date(new Date().getTime() - 364 * 24 * 60 * 60 * 1000)
            ).format("YYYYMMDD"),
            endDate: this.$moment(new Date()).format("YYYYMMDD"),
            hospitalCodeList: code
          }
        })
        .then(result => {
          if (result.success) {
            this.motheData = result.data;
            this.motheTjDate(this.motheData);
          }
        });
    },
    /* 今日医院排名接口 init */
    dayhosptilForm(code) { // 默认是今日
      let params = {
        startDate: this.$moment(new Date()).format("YYYYMMDD"),
        endDate: this.$moment(new Date()).format("YYYYMMDD"),
        hospitalCodeList: code,
        page: {
          current: 1,
          length: 30
        }
      };
      this.loadHosData(params);
    },
    /* 调阅次数 */
    readForm(code) {
      this.axios
        .get("/stat/queryStudyPvTotal.service", {
          params: {
            // currentDate: this.$moment(new Date()).format('YYYY-MM-DD'),
            startDate: "",
            endDate: "",
            hospitalCodeList: code
          }
        })
        .then(result => {
          if (result.success) {
            this.readData = result.data;
            this.readnumDate(this.readData, 2323);
          }
        });
    },
    /*  最新检查数据 滚动信息 */
    rollForm(code) {
      this.axios
        .get("/stat/queryStudyIndexTop.service", {
          params: {
            hospitalCodeList: code,
            page: {
              current: 1, // 当前页pageNum
              length: 3000 // 每页显示条数pageSize
            }
          }
        })
        .then(result => {
          if (result.success) {
            this.rollData = result.data;
            this.rollnumDate(this.rollData);
          }
        });
    }
  }
};
</script>

<style scoped lang="scss">
.home {
  background: url(../../assets/images/true.png) repeat-x;
  width: 100%;
  min-width: 1360px;
  height: calc(100vh + 110px); //130px
  background-size: 100% 100%;
  color: white;
  overflow: hidden;
}
</style>
<style lang="scss">
.el-scrollbar__wrap {
  overflow: scroll;
  height: 100%;
  background: #040b22;
  /* border: 1px solid #000; */
}

.el-autocomplete-suggestion li {
  padding: 0 20px;
  margin: 0;
  line-height: 34px;
  cursor: pointer;
  color: #fff;
  font-size: 13px;
  list-style: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.el-autocomplete-suggestion li.highlighted,
.el-autocomplete-suggestion li:hover {
  background-color: #03374e;
}

.el-scrollbar__thumb {
  background-color: #2f3343;
}

.el-popper[x-placement^="bottom"] {
  border: 1px solid #3f6767;
}

.el-popper[x-placement^="bottom"] .popper__arrow {
  display: none;
}

.el-picker-panel {
  background: #081e33;
}

.el-date-table td {
  width: 32px;
  padding: 4px 0;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  position: relative;
  color: #fff;
}

.el-date-table th {
  padding: 5px;
  color: #fff;
  font-weight: 400;
  text-align: center;
  border-bottom: 1px solid #096372;
}

.el-date-table td.today span {
  color: #c70fff;
  font-weight: 700;
}

.el-date-table td.today span {
  color: #c70fff;
  font-weight: 700;
}

.el-date-table td.available:hover {
  color: #c70fff;
}

.el-date-table td.disabled div {
  background-color: #f5f7fa;
  opacity: 1;
  cursor: not-allowed;
  background: #0a2b4c;
}

.el-date-table td.next-month,
.el-date-table td.prev-month {
  color: #676c77;
}
.el-date-table td.current:not(.disabled) span {
  color: #fff;
  background-color: #3c6c9a;
}
.el-range-editor--mini .el-range-separator {
  font-size: 12px;
  color: #fff;
}
</style>
