<template>
    <div class="databox">
        <!--左上边框-->
        <div class="line-box">
            <i class="t-l-line"></i>
            <i class="l-t-line"></i>
        </div>
        <!--右上边框-->
        <div class="line-box">
            <i class="t-r-line"></i>
            <i class="r-t-line"></i>
        </div>
        <!--左下边框-->
        <div class="line-box">
            <i class="l-b-line"></i>
            <i class="b-l-line"></i>
        </div>
        <!--右下边框-->
        <div class="line-box">
            <i class="r-b-line"></i>
            <i class="b-r-line"></i>
        </div>

    </div>
</template>

<script>
    export default {
        name: "databox",
        /* props: {
          title: String,
          dheight: Number,
          icon: String,
          boxb: {
            type: Boolean,
            default: true
          }
        } */
    };
</script>

<style lang="scss">
    .databox {
        .line-box {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 0;

            i {
                background-color: #00EAFF;
                position: absolute;

                &.l-t-line {
                    width: 1px;
                    height: 20px;
                    left: 0px;
                    top: 1px;
                }

                &.t-l-line {
                    height: 1px;
                    width: 20px;
                    left: 0px;
                    top: 0px;
                }

                &.t-r-line {
                    height: 1px;
                    width: 20px;
                    right: 1px;
                    top: 0px;
                }

                &.r-t-line {
                    width: 1px;
                    height: 20px;
                    right: 1px;
                    top: 1px;
                }

                &.l-b-line {
                    width: 1px;
                    height: 20px;
                    left: 0px;
                    bottom: 0px;
                }

                &.b-l-line {
                    height: 1px;
                    width: 20px;
                    left: 0px;
                    bottom: 0px;
                }

                &.r-b-line {
                    width: 1px;
                    height: 20px;
                    right: 1px;
                    bottom: 1px;
                }

                &.b-r-line {
                    height: 1px;
                    width: 20px;
                    right: 1px;
                    bottom: 0.5px;
                }
            }
        }
    }
</style>
