<template>
    <div class="statistics">
        <databox></databox>
        <div class="title">
            <span></span>24小时检查人次
        </div>
        <div class="statistics-map">
            <div class="leo-three" ref="leo-two"></div>
        </div>
    </div>
</template>

<script>
    import Databox from "./Databox";

    export default {
        name: "Statistics2",
        components: {Databox},
        data() {
            return {
                myChart: null
            }
        },
        computed: {
            indexData() {
                return this.$store.state.indexData
            }
        },
        watch:{
            indexData:{
                handler(){
                    if (this.indexData.studyTotalCurrentDate)
                    this.$nextTick(function () {
                        this.loadData();
                    })
                },
                immediate:true
            }
        },
        methods: {
            loadData() {
                let hour = '';
                let sData = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
                let o = this.indexData.studyTotalCurrentDate.studyHours
                for (let i = 0;i<o.length;i++){
                    let e = o[i];
                    hour = parseInt(e.hour) -1
                    sData[hour] = e.studyCount
                }
                this.myChart = this.$echarts.init(this.$refs['leo-two'])
                let options = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                        }
                    },
                    grid: {
                        left: '8%',
                        right: '8%',
                        bottom: '4%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            boundaryGap:false,
                            data:['01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00'],
                            axisTick: {
                                show: false,
                                alignWithLabel: true
                            },
                            axisLine: {
                                lineStyle: {
                                    color: 'rgba(7,59,71,1)'
                                }
                            },
                            axisLabel: {
                                textStyle: {
                                    color: '#ffffff',
                                    fontSize: 11
                                }
                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            min: 0,
                            splitNumber: 3,
                            axisLine: {
                                show: false,
                                lineStyle: {
                                    color: 'rgba(7,59,71,1)'
                                }
                            },
                            splitLine: {
                                show: true,
                                lineStyle: {
                                    color: 'rgba(0,234,255,0.2)',
                                }
                            },
                            axisLabel: {
                                textStyle: {
                                    color: '#ffffff',
                                    fontSize: 11
                                }
                            }
                        }
                    ],
                    series: [
                        {
                            name: '检查人次',
                            type: 'bar',
                            barWidth: '4px',
                            data: sData,//[70, 130, 50, 52, 120, 134, 160, 130, 70, 52, 100, 134, 190, 130, 90, 52, 80, 134, 190, 130, 105, 52, 150, 134],
                            color: function(params) {
                                //首先定义一个数组
                                var colorList = [
                                '#41B79A','#F5A64C'
                                ];
                                if(params.dataIndex % 2 == 0){
                                    return colorList[0]
                                }else{

                                    return colorList[1]
                                }

                            },
                            itemStyle: {
                                color: { // 柱子的颜色
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0.5,
                                    y2: 0.5,
                                    x3: 0,
                                    y3: 1,
                                    colorStops: [ // 柱子的颜色渐变 是个数组
                                        {offset: 0, color: 'rgba(0,246,255,1)'},
                                        {offset: 0.5, color: 'rgba(0,246,255,0.75)'},
                                        {offset: 1, color: 'rgba(0,234,255,0.5)'}
                                    ],
                                    globalCoord: false // 缺省为 false

                               },
                                barBorderRadius: [0, 4, 0, 0] //（柱子的圆角，顺时针左上，右上，右下，左下）
                            },

                        },


                    ]
                };
                this.myChart.setOption(options)
                //this.myChart.showLoading();加载动画
                //  myChart.hideLoading(); 隐藏加载动画

            }
        }
    }
</script>

<style scoped lang="scss">
.statistics {
    width: 480px;
    height: 330px;
    overflow: hidden;
    position: relative;
    margin-bottom: 20px;
    opacity: 0.8;

    .title {
        width: 100%;
        height: 28px;
        line-height: 28px;
        margin-top: 36px;
        color: #00eaff;
        padding-left: 40px;
        font-size: 20px;
        font-weight: 550;
        letter-spacing: 3px;

        span {
            display: block;
            float: left;
            width: 10px;
            height: 10px;
            background: #00eaff;
            margin-right: 10px;
            margin-top: 7px;
        }
    }

    .statistics-map {
        .leo-three {
            margin-top: -30px;
            height: 280px;
            /* 不加这个，4个直角的组件会影响到效果 */
            position: relative;
            z-index: 9999;
        }
    }
}

.statistics::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 199%;
    height: 199%;
    border: 1px solid #61d2f7;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scale(0.5, 0.5);
    transform: scale(0.5, 0.5);
    z-index: 1;
}
</style>
