<template>
  <div class="center-number" v-if="indexData.studyTotalHistory">
    <div class="top">
      <div class="top-item">
        <div class="one">所有检查量(次)</div>
        <!--{{indexData.studyTotalHistory.studyTotal}}-->
        <ul class="box">
          <li
            :class="{'number-item': !isNaN(item) }"
            v-for="(item,index) in computeNumber"
            :key="index"
          >
            <span v-if="!isNaN(item)" id="Number">
              <i ref="numberItem">0123456789</i>
            </span>
            <span v-else class="dou">{{item}}</span>
          </li>
        </ul>
      </div>
      <div class="top-item" style="position: relative;">
        <div class="one" style="float: right;position: relative; right: 46%;">云影像调阅(次)</div>
        <!--{{readData.total}}-->
        <ul class="box" style="float: right;">
          <li
            :class="{'number-item': !isNaN(item) }"
            v-for="(item,index) in computeNumber1"
            :key="index"
          >
            <span v-if="!isNaN(item)" id="Number">
              <i ref="numberItem1">0123456789</i>
            </span>
            <span v-else class="dou">{{item}}</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="cloud-search">
      <div class="one" style="cursor: pointer;" @click="skipBtn">
        <img
          src="../assets/images/yyx.png"
          style="height: 14px;display: inline-block;position: relative;top: 3px;left: -5px;"
          alt
        />
        数据中心
      </div>
      <!-- <div class="five" style="cursor: pointer;" @click="skipBtnse">
        <img
          src="../assets/images/yxd.png"
          style="height: 14px;display: inline-block;position: relative;top: 3px;left: -5px;"
          alt
        />
        云心电中心
      </div> -->
      <el-autocomplete
        v-model="state"
        :fetch-suggestions="querySearchAsync"
        :trigger-on-focus="true"
        placeholder="搜索"
        @select="handleSelect"
        style="float: left;margin: 0 20px 0 4px;height: 40px;"
      ></el-autocomplete>
      <!-- <span class="three" @click="serchBack">
        <i class="el-icon-search"></i>
      </span> -->

      <span class="four" v-if="mapLevel!==1">
        <img src="../assets/images/fanhui.png" style="    padding: 3px;" @click="handleBack" alt />
      </span>
    </div>
  </div>
</template>

<script>
import ICountUp from "vue-countup-v2";
import { mapGetters, mapState } from "vuex";
import VueEvent from "../utils/VueEvent";
import { globalUrl } from '../utils/common';
export default {
  name: "CenterNumber",
  components: {
    ICountUp
  },
  data() {
    return {
      test: "",
      delay: 100,
      options: {
        useEasing: true,
        useGrouping: true,
        separator: "", // 使用千分位时分割符号
        decimal: "", // 小数位分割符号
        prefix: "", // 前置符号
        suffix: "" // 后置符号，可汉字
      },
      //搜索
      restaurants: [],
      state: "",
      timeout: null,
      computeNumber: ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0"],
      computeNumber1: ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0"],
      total: 0
    };
  },
  watch: {
    currentHospital: {
      handler(v) {
        if (v instanceof Array) {
          this.state = "";
        } else if (v instanceof Object) {
          this.state = v.name;
        }
      }
    },
    mapLevel(val, val2) {
      //console.log(val, 9999995454);
      // if (val == 1 && val2 == 2) {
      if (val == 1) {
        let code = this.$store.state.HospitalCode.join(",");
        VueEvent.$emit("test123", code);
      }
    }
  },
  computed: {
    ...mapGetters(["chinaMapData", "indexData", "readData"]),
    ...mapState(["currentHospital", "mapLevel"]),
    indexData() {
      return this.$store.state.indexData;
    },
    readData() {
      return this.$store.state.readData;
    }
  },
  methods: {
    //跳转影像中心
    skipBtn() {
      window.open(globalUrl() + '/#/main/getScreenageBdata?from=bigdata');
    },
    //跳转心电中心
    // skipBtnse() {
    //   let userInfojson = JSON.parse(sessionStorage.getItem("userInfo"));
    //   window.open(
    //     "http://testecg.jdimage.cn/#/monitorlog/dynamic?token=" +
    //       userInfojson.token
    //   );
    // },
    //搜索框自动匹配
    querySearchAsync(queryString, callback) {
      let datajson = this.chinaMapData;
      var list = [{}];
      var list2 = [{}];
      //调用的后台接口
      //let url = 后台接口地址 + queryString;
      //从后台获取到对象数组
      //在这里为这个数组中每一个对象加一个value字段, 因为autocomplete只识别value字段并在下拉列中显示
      for (let i of datajson) {
        i.value = i.name; //将想要展示的数据作为value
      }
      list = datajson;
      if (this.state) {
        list2 = list.filter(d => {
          return d.value.indexOf(this.state) >= 0;
        });

        return callback(list2);
      } else {
        callback(list);
      }
    },

    handleSelect(item) {
      // this.$emit("handleSelectHospital", item);
       VueEvent.$emit("handleSelectHospital", item);
      this.$store.commit("setCurrentHospital", item);
      this.$store.commit("setActiveCode", item.code);

      // 选中医院的时候刷新数据
      let params = {
        startDate: this.$moment(new Date()).format("YYYYMMDD"),
        endDate: this.$moment(new Date()).format("YYYYMMDD"),
        hospitalCodeList: item.code,
        page: {
          current: 1,
          length: 10
        }
      };
      this.$store.dispatch("loadIndexTjDate", item.code);
      this.$store.dispatch("loadMotheTjDate", item.code);
      this.$store.dispatch("loadHosData", params);
      this.$store.dispatch("loadReadForm", item.code);
      this.$store.dispatch("loadRollForm", item.code);
    },
    handleBack() {
      //返回
      this.$store.commit("setHandleBack", Math.random());
      //console.log(this.$store.state.mapLevel, 999);
    },
    //搜索按钮
    serchBack() {
      if (
        this.$store.state.mapLevel * 1 > 1 &&
        (!this.state || this.state == "" || this.state == undefined)
      ) {
        this.$store.state.mapLevel = 1;
        VueEvent.$emit("test456");
      }
    },
    // 定时增长数字
    increaseNumber() {
      let self = this;
      this.timer = setInterval(() => {
        self.newNumber = self.newNumber + getRandomNumber(1, 100);
        self.setNumberTransform();
      }, 3000);
    },
    // 设置每一位数字的偏移
    setNumberTransform() {
      let numberItems = this.$refs.numberItem;
      let numberItems1 = this.$refs.numberItem1;
      const studyrf = (
        this.$store.state.indexData.studyTotalHistory.studyTotal + ""
      ).padStart(10, "0"); //jiancha
      const studyrf1 = (this.$store.state.readData.total + "").padStart(
        10,
        "0"
      ); //diaoyue
      this.computeNumber = [];
      this.computeNumber1 = [];
      for (let i = 0; i < (studyrf + "").length; i++) {
        this.computeNumber.push(studyrf.slice(i, i + 1));
      }
      for (let i = 0; i < (studyrf1 + "").length; i++) {
        this.computeNumber1.push(studyrf1.slice(i, i + 1));
      }
      let numberArr = this.computeNumber.filter(item => !isNaN(item));
      for (let index = 0; index < numberItems.length; index++) {
        let elem = numberItems[index];
        elem.style.transform = `translate(-50%, -${numberArr[index] * 10}%)`;
      }
      let numberArr1 = this.computeNumber1.filter(item => !isNaN(item));
      for (let index = 0; index < numberItems1.length; index++) {
        let elem = numberItems1[index];
        elem.style.transform = `translate(-50%, -${numberArr1[index] * 10}%)`;
      }
    }
  },
  mounted() {
    this.increaseNumber();
  }
};
function getRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}
</script>

<style lang="scss">
.center-number {
  width: 100%;
  margin-top: 40px;
  min-height: 100px;
  overflow: hidden;
  opacity: 0.8;

  .top {
    display: flex;
    overflow: hidden;
    margin-bottom: 20px;

    .top-item {
      flex: 1;

      .one {
        width: 140px;
        height: 22px;
        font-size: 16px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 22px;
        letter-spacing: 1px;
        margin-bottom: 15px;
      }

      .leo-countup {
        font-size: 28px;
        color: rgba(255, 255, 255, 1);
        width: 323px;
        height: 39px;
        letter-spacing: 19px;
        padding-left: 10px;
        overflow: hidden;
        margin-top: 20px;
        position: relative;

        .location {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          right: 0;
          right: 0;
          display: flex;

          span {
            flex: 1;
            background: #03374e;
            border: 1px solid #07515e;
            margin-left: 5px;
          }
        }

        .leo-num {
          position: relative;
          z-index: 100;
          top: 3px;
          left: 1px;
          display: inline-block;
        }
      }
    }
  }

  .cloud-search {
    width: 100%;
    height: 40px;
    margin-top: 40px;
    line-height: 40px;

    .el-input__inner {
      width: 300px;
      height: 32px;
      line-height: 32px;
      border-radius: 1px;
      color: #00eaff;
      background: none;
      opacity: 0.8;
      border: 1px solid #689ea2;
    }

    .one {
      width: 130px;
      height: 30px;
      line-height: 30px;
      margin-top: 5px;
      float: left;
      color: #ffd600;
      border-radius: 1px;
      border: 1px solid rgba(255, 214, 0, 1);
      font-size: 14px;
      text-align: center;
      position: absolute;
      top: 43px;

      i {
        margin-right: 5px;
      }
      &:hover {
        background: #000;
        color: #de9818;
         border: 1px solid #de9818;
      }
    }
    .five {
      width: 130px;
      height: 30px;
      line-height: 30px;
      margin-top: 5px;
      float: right;
      color: rgb(199, 15, 255);
      border-radius: 1px;
      border: 1px solid rgb(199, 15, 255);
      font-size: 14px;
      text-align: center;
      position: absolute;
      top: 43px;
      right: 0;

      i {
        margin-right: 5px;
      }
      &:hover {
        background: #000;
        color: #b27cec;
         border: 1px solid #b27cec;
      }
    }

    .two {
      float: left;
      width: 300px;
      margin-top: 5px;
      margin-left: 15px;

      .el-input__inner {
        background: none;
        border: 1px solid rgba(0, 234, 255, 0.5);
        color: white;
        height: 0.16rem;
        border-radius: 1px;
        opacity: 0.8;
      }

      ::-moz-placeholder {
        color: #00eaff;
      }

      ::-webkit-input-placeholder {
        color: #00eaff;
      }

      :-ms-input-placeholder {
        color: #00eaff;
      }
    }

    .three {
      float: left;
      width: 40px;
      height: 30px;
      border-radius: 1px;
      border: 1px solid rgba(0, 234, 255, 1);
      margin-top: 5px;
      margin-left: 15px;
      line-height: 30px;
      text-align: center;
      cursor: pointer;

      i {
        font-size: 14px;
        color: #00eaff;
      }
    }

    .four {
      float: right;
      width: 40px;
      height: 30px;
      border-radius: 1px;
      border: 1px solid #ffd600;
      margin-top: 5px;
      margin-left: 15px;
      line-height: 30px;
      text-align: center;
      cursor: pointer;

      i {
        font-size: 14px;
        color: rgba(255, 214, 0, 1);
      }
    }
  }
}

.box {
  overflow: hidden;
  > li > .dou {
    display: inline-block;
    font-size: 30px;
    height: 60px;
    line-height: 80px;
    color: #ff6744;
  }
  li {
    float: left;
  }
}
.number-item {
  width: 29px;
  background: #03374e;
  border: 1px solid #07515e;
  padding-top: 5px;

  height: 40px;
  background-size: 100% 100%;
  float: left;
  overflow: hidden;
  margin: 0 2px;

  & > span {
    position: relative;
    display: inline-block;
    // margin-right: 10px;
    width: 100%;
    height: 100%;
    writing-mode: vertical-rl;
    text-orientation: upright;
    overflow: hidden;
    color: #fff;
    font-size: 25px;
    & > i {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      transition: transform 0.5s ease-in-out;
      letter-spacing: 10px;
    }
  }
}
.el-scrollbar__wrap {
  border-bottom: 1px solid #3f6767;
}
</style>
