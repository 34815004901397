<template>
  <div class="StatisticsTotal">
    <databox></databox>
    <div class="title">
      <span></span>月检查量走势
      <div class="echart-button" @click="tendency()">
         <img
          src="../assets/images/qushi.png"
          style="position: relative;top: 2px;left: -5px;"
          alt
        />
        <!--<i class="el-icon-s-help"></i>-->趋势
      </div>
    </div>

    <div class="StatisticsTotal-map">
      <div class="leo-three" ref="leo-one"></div>
    </div>

    <!-- 弹窗 -->
    <Trend v-if="dialogTableVisibles" ref="trend" />
  </div>
</template>

<script>
import Databox from "./Databox";
import Trend from "../components/Trend";

export default {
  name: "StatisticsTotal2",
  components: { Databox, Trend },
  data() {
    return {
      myChart: null,
      tableDate: [],
      //趋势弹出框num: 0,
      dialogTableVisibles: false,
      /* 折现图 */
      lineChart: null
    };
  },
  computed: {
    motheData() {
      return this.$store.state.motheData;
    },
    chinaMapData() {
      return this.$store.state.chinaMapData;
    }
  },
  watch: {
    motheData: {
      handler() {
        if (this.motheData)
          this.$nextTick(function() {
            this.loadData();
          });
      },
      immediate: true
    }
  },
  methods: {
    loadData() {
      let xData = [];
      let sData = [];
      for (let i = 0; i < this.motheData.length; i++) {
        let e = this.motheData[i];
        xData.push(e.date.substr(e.date.length - 2));
        sData.push(e.studyTotal);
      }
      this.myChart = this.$echarts.init(this.$refs["leo-one"]);
      let options = {

        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            }
        },
        grid: {
          left: "8%",
          right: "8%",
          bottom: "4%",
          containLabel: true
        },
        xAxis: {
          type: "category",
          data: xData,
          boundaryGap: false,
          //data:dateList,
          axisLine: {
            lineStyle: {
              color: "rgba(7,59,71,1)"
            }
          },
          axisLabel: {
            textStyle: {
              color: "#ffffff",
              fontSize: 11
            }
          }
        },
        yAxis: {
          type: "value",
          min: 0,
          splitNumber: 4,
          axisLine: {
            show: false,
            lineStyle: {
              color: "rgba(7,59,71,1)"
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(0,234,255,0.2)"
            }
          },
          axisLabel: {
            textStyle: {
              color: "#ffffff",
              fontSize: 11
            }
          }
        },
        series: [
          {
            name: "月检查量",
            barWidth: "4px",
            data: sData,
            type: "line",
            color: function(params) {
              //首先定义一个数组
              var colorList = ["#41B79A", "#F5A64C"];
              if (params.dataIndex % 2 == 0) {
                return colorList[0];
              } else {
                return colorList[1];
              }
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(0,234,255,1)" // 0% 处的颜色
                  },
                  {
                    offset: 0.7,
                    color: "rgba(0,234,255,0.7)" // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(0,234,255,0.1)" // 100% 处的颜色
                  }
                ],
                global: false // 缺省为 false
              }
            },
            itemStyle: {
              normal: {
                color: "#FFFFFF",
                lineStyle: {
                  color: "#00EAFF",
                  width: 1
                }
              }
            }
          }
        ]
      };
      this.myChart.setOption(options);
    },
    // 趋势弹出框
    tendency() {
      this.dialogTableVisibles = true;
      this.$nextTick(() => {
        // 父组件调用子组件的方法
        this.$refs.trend.init();
      });
    }
  }
};
</script>

<style scoped lang="scss">
.StatisticsTotal {
  width: 480px;
  height: 330px;
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
  /*  opacity: 0.8; */

  .title {
    width: 100%;
    height: 28px;
    line-height: 28px;
    margin-top: 36px;
    color: #00eaff;
    padding-left: 40px;
    font-size: 20px;
    font-weight: 550;
    letter-spacing: 3px;
        position: relative;
    z-index: 999;

    span {
      display: block;
      float: left;
      width: 10px;
      height: 10px;
      background: #00eaff;
      margin-right: 10px;
      margin-top: 7px;
    }

    .echart-button {
      float: right;
      width: 70px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border-radius: 1px;
      border: 1px solid rgba(18, 255, 254, 1);
      color: #00eaff;
      font-size: 14px;
      margin-right: 20px;
      position: relative;
      z-index: 5;
      cursor: pointer;

      i {
        margin-right: 3px;
      }
    }
  }

  .StatisticsTotal-map {
    .leo-three {
      margin-top: -30px;
      height: 280px;
      /* 不加这个，4个直角的组件会影响到效果 */
      position: relative;
      z-index: 99;
    }
  }
}

.StatisticsTotal::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 199%;
  height: 199%;
  border: 1px solid #61d2f7;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: scale(0.5, 0.5);
  transform: scale(0.5, 0.5);
  z-index: 1;
}
</style>

