<template>
  <div class="statistics" v-if="indexData.studyTotalCurrentDate">
    <databox></databox>
    <div class="title">
      <span></span>当日数据统计
    </div>

    <div class="reveal">
      <div class="reveal-item">
        <div class="reveal-title">检查量</div>
        <div class="reveal-num">
          {{indexData.studyTotalCurrentDate.studyTotal}}
          <span>次</span>
        </div>
      </div>
      <div class="reveal-item">
        <div class="reveal-title">文件大小</div>
        <div class="reveal-num">{{fileSizTotal}}</div>
      </div>
    </div>

    <div class="reveal">
      <div class="reveal-item">
        <div class="reveal-title">报告数量</div>
        <div class="reveal-num">
          {{indexData.studyTotalCurrentDate.reportTotal}}
          <span>份</span>
        </div>
      </div>
      <div class="reveal-item">
        <div class="reveal-title">患者人数</div>
        <div class="reveal-num">
          {{indexData.studyTotalCurrentDate.userCount}}
          <span>人</span>
        </div>
      </div>
    </div>

    <div class="reveal-total">
      <div class="reveal-total-item">
        <div class="reveal-total-line">
          <span>阳</span>
          <el-progress
            v-if="yangData"
            :percentage="yangData"
            :color="yang"
            :stroke-width="2"
            :text-inside="true"
          ></el-progress>
          <div class="reveal-total-num" style="color: #FFD600;">
            <!--{{indexData.studyTotalCurrentDate.mas1Count}}-->
            {{jisuan(yangData)}}
          </div>
        </div>
        <div class="reveal-total-line">
          <span>阴</span>
          <el-progress
            v-if="yinData"
            :percentage="yinData"
            :color="yin"
            :stroke-width="2"
            :text-inside="true"
          ></el-progress>
          <div class="reveal-total-num" style="color: #00EAFF;">{{jisuan(yinData)}}</div>
        </div>
        <div class="reveal-total-line">
          <span>未知</span>
          <el-progress
            v-if="weizhiData"
            :percentage="weizhiData"
            :color="weizhi"
            :stroke-width="2"
            :text-inside="true"
          ></el-progress>
          <div class="reveal-total-num" style="color: #C70FFF;">{{jisuan(weizhiData)}}</div>
        </div>
      </div>

      <div class="reveal-total-item">
        <div class="reveal-total-line">
          <span>男</span>
          <el-progress
            v-if="nanData"
            :percentage="nanData"
            :color="yang"
            :stroke-width="2"
            :text-inside="true"
          ></el-progress>
          <div class="reveal-total-num" style="color: #FFD600;">{{jisuan(nanData)}}</div>
        </div>
        <div class="reveal-total-line">
          <span>女</span>
          <el-progress
            v-if="nvData"
            :percentage="nvData"
            :color="yin"
            :stroke-width="2"
            :text-inside="true"
          ></el-progress>
          <div class="reveal-total-num" style="color: #00EAFF;">{{jisuan(nvData)}}</div>
        </div>
        <div class="reveal-total-line">
          <span>未知</span>
          <el-progress
            v-if="weizhisData"
            :percentage="weizhisData"
            :color="weizhi"
            :stroke-width="2"
            :text-inside="true"
          ></el-progress>
          <div class="reveal-total-num" style="color: #C70FFF;">{{jisuan(weizhisData)}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Databox from "./Databox";

export default {
  name: "Statistics",
  components: { Databox },
  data() {
    return {
      yin: "#12FFFE",
      yang: "#FFD600",
      weizhi: "#C70FFF",
      progressNum: 0
    };
  },
  computed: {
    indexData() {
      return this.$store.state.indexData;
    },
    fileSizTotal() {
      // eslint-disable-next-line no-console
      return this.getSizeString(
        this.indexData.studyTotalCurrentDate.fileSizTotal / 1024 / 1024
      );
    },
    //阴阳未知
    yangData() {
      if (this.indexData) {
        if (this.indexData.studyTotalCurrentDate.studyTotal == 0) {
          return 0.00001;
        }
        let a =
          (this.indexData.studyTotalCurrentDate.mas1Count /
            this.indexData.studyTotalCurrentDate.studyTotal || 0) * 100;
        return a == 0 ? 0.00001 : a;
      }
    },
    yinData() {
      if (this.indexData) {
        if (this.indexData.studyTotalCurrentDate.studyTotal == 0) {
          return 0.00001;
        }
        let a =
          (this.indexData.studyTotalCurrentDate.mas0Count /
            this.indexData.studyTotalCurrentDate.studyTotal || 0) * 100;
        return a == 0 ? 0.00001 : a;
      }
    },
    weizhiData() {
      if (this.indexData) {
        if (this.indexData.studyTotalCurrentDate.studyTotal == 0) {
          return 0.00001;
        }
        let a =
          ((this.indexData.studyTotalCurrentDate.studyTotal -
            this.indexData.studyTotalCurrentDate.mas1Count -
            this.indexData.studyTotalCurrentDate.mas0Count) /
            this.indexData.studyTotalCurrentDate.studyTotal || 0) * 100;
        return a == 0 ? 0.00001 : a;
      }
    },
    //男女未知
    nanData() {
      if (this.indexData) {
        if (this.indexData.studyTotalCurrentDate.studyTotal == 0) {
          return 0.00001;
        }
        let a =
          (this.indexData.studyTotalCurrentDate.sex1Count /
            this.indexData.studyTotalCurrentDate.studyTotal) *
          100;
        return a == 0 ? 0.00001 : a;
      }
    },
    nvData() {
      if (this.indexData) {
        if (this.indexData.studyTotalCurrentDate.studyTotal == 0) {
          return 0.00001;
        }
        let a =
          (this.indexData.studyTotalCurrentDate.sex0Count /
            this.indexData.studyTotalCurrentDate.studyTotal) *
          100;
        return a == 0 ? 0.00001 : a;
      }
    },
    weizhisData() {
      if (this.indexData) {
        if (this.indexData.studyTotalCurrentDate.studyTotal == 0) {
          return 0.00001;
        }
        let a =
          ((this.indexData.studyTotalCurrentDate.studyTotal -
            this.indexData.studyTotalCurrentDate.sex0Count -
            this.indexData.studyTotalCurrentDate.sex1Count) /
            this.indexData.studyTotalCurrentDate.studyTotal || 0) * 100;
        return a == 0 ? 0.00001 : a;
      }
    }
  },
  created() {
    // this.getSizeString(this.indexData.studyTotalCurrentDate.fileSizTotal)
    //打印下获得数据结果
  },
  filters: {},
  methods: {
    getSizeString(size) {
      if (!size) size = 0;
      if (0 <= size && size < 1024) return size.toFixed(2) + "M";
      else if (1024 <= size && size < 1024 * 1024)
        return (size / 1024).toFixed(2) + "G";
      else if (1024 * 1024 < size)
        return (size / (1024 * 1024)).toFixed(2) + "T";
    },
    jisuan(val) {
      let valF = parseFloat(val);
      return valF.toFixed(2)+'%';
    }
  },
  mounted() {
    //this.startProgress()
  }
};
</script>
<style lang="scss">
  .el-progress-bar__innerText{
    color: #2e2d2d !important;
  }
</style>
<style scoped lang="scss">
.statistics {
  width: 480px;
  height: 330px;
  overflow: hidden;
  position: relative;
  /* border: 1px solid #12FFFE; */
  margin-bottom: 20px;
  opacity: 0.8;

  .title {
    width: 100%;
    height: 28px;
    line-height: 28px;
    margin-top: 36px;
    color: #00eaff;
    padding-left: 40px;
    font-size: 20px;
    font-weight: 550;
    letter-spacing: 3px;

    span {
      display: block;
      float: left;
      width: 10px;
      height: 10px;
      background: #00eaff;
      margin-right: 10px;
      margin-top: 7px;
    }
  }

  .reveal {
    display: flex;
    margin-top: 20px;

    .reveal-item {
      flex: 1;

      .reveal-title {
        height: 17px;
        line-height: 17px;
        padding-left: 40px;
        font-size: 12px;
      }

      .reveal-num {
        width: 181px;
        height: 55%;
        line-height: 37px;
        margin-left: 31px;
        margin-top: 9px;
        background: #03253c;
        font-size: 24px;
        padding-left: 9px;
        border-radius: 1px;
        letter-spacing: 2px;

        span {
          font-size: 12px;
          display: inline-block;
          margin-left: 5px;
        }
      }
    }
  }

  .reveal-total {
    display: flex;
    margin-top: 20px;
    margin-top: 20px;

    .reveal-total-item {
      flex: 1;

      .reveal-total-line {
        width: 100%;
        overflow: hidden; /* 不加这个撑不起来高度 */
        padding-left: 40px;
        display: block;

        span {
          display: block;
          float: left;
          width: 40px;
          height: 17px;
          line-height: 17px;
          /* font-size: 12px; */
          margin-right: 5px;
          transform: scale(0.9);

          .el-progress-bar__outer {
            background-color: #2f3343 !important;
          }
        }

        .el-progress {
          width: 50px;
          float: left;
        }

        .reveal-total-num {
          float: left;
          padding-left: 5px;
          font-size: 12px;
          transform: scale(0.9);
        }
      }
    }
  }
}

.statistics::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 199%;
  height: 199%;
  border: 1px solid #61d2f7;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: scale(0.5, 0.5);
  transform: scale(0.5, 0.5);
  z-index: 1;
}
</style>
<style>
.el-progress .el-progress-bar__outer {
  background-color: #2f3343 !important;
}
</style>
