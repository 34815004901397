<template>
  <!-- 趋势弹出框 -->
  <el-dialog
    :visible.sync="dialogTableVisibles"
    class="lineChart"
    @close="$emit('update:show', false)"
  >
    <div class="searchBox">
      <div class="searchBox-item" @click="selectType1(10)" :class="{active:active == 10}">检查量趋势</div>
      <div class="searchBox-item" @click="selectType2(20)" :class="{active:active == 20}">患者数量趋势</div>
      <div class="searchBox-item" @click="selectType3(30)" :class="{active:active == 30}">报告阴阳性趋势</div>
    </div>
    <!-- 这里面写一个时间的搜索框 -->
    <!--<div class="date-picker">
            <button class="filter-dy">
                <img style="width: 16px;" src="../assets/images/dayin.png" alt/>
            </button>
            <el-date-picker
                    v-model="listQuery.info.gmtCreateStart"
                    type="date"
                    :picker-options="pickerBeginDateBefore"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    placeholder="开始日期"
                    style="width: 13%;"
            />
            <span class="until" style="color: #00EAFF;padding: 0 7px;">至</span>
            <el-date-picker
                    v-model="listQuery.info.gmtCreateEnd"
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    :picker-options="pickerBeginDateAfter"
                    placeholder="结束日期"
                    style="width: 13%;;"
            />
          
    </div>-->
    <div class="date-picker">
      <button class="filter-dy" @click="Print">
        <img style="width: 17px;" src="../assets/images/dayin.png" alt />
      </button>
      <el-date-picker
        v-model="timeData"
        type="daterange"
        size="mini"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd"
        class="data-pick"
        @change="handleFilter"
        unlink-panels
      ></el-date-picker>
      <!-- <button class="filter-search" @click="handleFilter">
        <span class="el-icon-search" ></span>
      </button> -->
    </div>
    <!-- 图表 -->
    <div class="leo-four" id="lineChart" ref="print"></div>
  </el-dialog>
</template>

<script>
import print from "@/utils/print.js";
export default {
  name: "Trend",
  data() {
    return {
      dialogTableVisibles: false, // 弹框显示
      lineChart: null,
      active: 10,
      tableDate: {
        nameList: [],
        dateList: []
      },
      /* 日期选择 */
      getPassYearFormatDate() {
        var nowDate = new Date();
        var date = new Date(nowDate);
        date.setDate(date.getDate() - 365);
        var seperator1 = "-";
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var strDate = date.getDate();
        if (month >= 1 && month <= 9) {
          month = "0" + month;
        }
        if (strDate >= 0 && strDate <= 9) {
          strDate = "0" + strDate;
        }
        var formatDate = year + seperator1 + month + seperator1 + strDate;
        this.getNowFormatDate(formatDate);
      },
      /* 获取现在时间，并接受过去时间的值 */
      getNowFormatDate(formatDate) {
        var date = new Date();
        var seperator1 = "-";
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var strDate = date.getDate();
        if (month >= 1 && month <= 9) {
          month = "0" + month;
        }
        if (strDate >= 0 && strDate <= 9) {
          strDate = "0" + strDate;
        }
        var nowData = year + seperator1 + month + seperator1 + strDate;
        this.timeData = [formatDate, nowData]; // 默认赋值一年时间
      },
      //时间
      timeData: [],
      listQuery: {
        info: {
          gmtCreateStart: "",
          gmtCreateEnd: ""
        }
      },
      seriesData: []
    };
  },
  mounted() {
    this.getPassYearFormatDate();
  },
  computed: {
    motheData() {
      return this.$store.state.motheData;
    },
    chinaMapData() {
      return this.$store.state.chinaMapData;
    }
  },
  watch: {
    dialogTableVisibles(newVal, oldVal) {
      if (!newVal) {
        document.title = this.$titles;
      }

      this.getPassYearFormatDate();
    },
    motheData: {
      handler() {
        if (this.motheData)
          this.$nextTick(function() {
            //this.selectType1(10)
          });
      },
      immediate: true
    }
  },
  methods: {
    init() {
      this.dialogTableVisibles = true;
      this.$nextTick(function() {
        this.drawLine();
        //this.selectType1(type)
        this.selectType1(10);
      });
    },
    drawLine() {
      // 在 el-dialog 里面只能使用 原生JS获取DOM
      this.lineChart = this.$echarts.init(document.getElementById("lineChart"));
      let options = {
        //backgroundColor: "#040b22",
        legend: {
          data: this.tableDate.nameList,
          show: true,
          type: "plain",
          right: "3%",
          bottom: "93%",
          //selectedMode:false,//取消图例上的点击事件
          itemGap: 10,
          itemWidth: 10,
          itemHeight: 10,
          textStyle: {
            fontSize: 14, //字体大小
            color: "#929ea0" //字体颜色
          },
          icon: "circle"
        },
        axisPointer: {
          // 坐标轴指示器，坐标轴触发有效
          type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
        },
        xAxis: {
          type: "category", // 还有其他的type，可以去官网喵两眼哦
          data: this.tableDate.dateList, // x轴数据
          name: "", // x轴名称
          boundaryGap: false,
          // x轴名称样式
          axisTick: {
            show: false,
            alignWithLabel: true
          },
          axisLine: {
            lineStyle: {
              color: "rgba(18,255,254,0.1)"
            }
          },
          axisLabel: {
            rotate: 0, // 旋转角度
            lineHeight: 14,
            interval: 0, //设置X轴数据间隔几个显示一个，为0表示都显示
            textStyle: {
              color: "#929ea0",
              fontSize: 13
            }
          }
        },
        yAxis: {
          type: "value",
          name: "", // y轴名称
          // y轴名称样式
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(0,234,255,0.2)"
            }
          },
          axisLabel: {
            lineHeight: 14,
            textStyle: {
              color: "#929ea0",
              fontSize: 13
            }
          }
        },
        label: {},
        tooltip: {
          trigger: "axis",
          // 相对位置
          position: ["50%", "30%"]
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "5%",
          containLabel: true
        },
        series: this.seriesData,
        color: [
          "#8cc540",
          "#009f5d",
          "#019fa0",
          "#019fde",
          "#007cdc",
          "#887ddd",
          "#cd7bdd",
          "#ff5675",
          "#ff1244",
          "#ff8345",
          "#f8bd0b",
          "#d1d2d4",
          "#b0ff00",
          "#862b21"
        ]
      };
      this.lineChart.setOption(options);
    },

    // 检查量趋势
    selectType1(type) {
      this.active = type;
      //医院详情数据
      let motheDeils = this.chinaMapData;
      let code;

      if (this.$store.state.mapLevel == 1) {
        code = this.$getCode(this.$store.state.HospitalCode);
      } else {
        code = this.$getCode(this.$store.state.currentHospital);
      }
      code = code ? code : this.$getCode(this.$store.state.HospitalCode);
      this.axios
        .get("/stat/queryExamtypeTotalByMonth.service", {
          params: {
            // currentDate: this.$moment(new Date()).format('YYYY-MM-DD'),
            startDate: this.$moment(this.timeData[0]).format("YYYYMMDD"),
            endDate: this.$moment(this.timeData[1]).format("YYYYMMDD"),
            hospitalCodeList: code
          }
        })
        .then(result => {
          if (result.success) {
            const reult = [].concat(result.data);

            this.seriesData = []; //  数组源
            // 名字数组
            let nameList = Array.from(
              new Set(
                reult.map(d => {
                  return d.examineType;
                })
              )
            );

            // 日期数组
            let dateList = Array.from(
              new Set(
                reult.map(d => {
                  return d.studyDate;
                })
              )
            ).sort();
            nameList.forEach(d => {
              this.seriesData.push({
                name: d,
                data: [],
                type: "line",
                itemStyle: {
                  normal: {
                    lineStyle: {
                      width: 1 //折线宽度
                    }
                  }
                }
              });
            });
            reult.forEach(d => {
              this.seriesData.forEach(dd => {
                dateList.forEach(ddd => {
                  if (d.studyDate == ddd && dd.name == d.examineType) {
                    dd.data.push(d.total);
                  }
                });
              });
            });
            this.tableDate = { nameList, dateList };
            // 重新加载数据
             lineChart.removeAttribute("_echarts_instance_");
            this.drawLine();
          }
        });
    },
    // 患者数量趋势
    selectType2(type) {
      this.active = type;
      //医院详情数据
      let motheDeils = this.chinaMapData;
      let code;

      if (this.$store.state.mapLevel == 1) {
        code = this.$getCode(this.$store.state.HospitalCode);
      } else {
        code = this.$getCode(this.$store.state.currentHospital);
      }
      code = code ? code : this.$getCode(this.$store.state.HospitalCode);
      this.axios
        .get("/stat/querySexTotalByMonth.service", {
          params: {
            // currentDate: this.$moment(new Date()).format('YYYY-MM-DD'),
            startDate: this.$moment(this.timeData[0]).format("YYYYMMDD"),
            endDate: this.$moment(this.timeData[1]).format("YYYYMMDD"),
            hospitalCodeList: code
          }
        })
        .then(result => {
          if (result.success) {
            const reult = [].concat(result.data);
            this.seriesData = []; //  数组源
            // 男数组
            let sex1List = Array.from(
              new Set(
                reult.map(d => {
                  return d.sex1Count;
                })
              )
            );
            // 女数组
            let sex0List = Array.from(
              new Set(
                reult.map(d => {
                  return d.sex0Count;
                })
              )
            );
            // 日期数组
            let dateList = Array.from(
              new Set(
                reult.map(d => {
                  return d.date;
                })
              )
            ).sort();
            this.seriesData = [
              {
                name: "男",
                data: sex1List,
                type: "line",
                itemStyle: {
                  normal: {
                    lineStyle: {
                      width: 1 //折线宽度
                    }
                  }
                }
              },
              {
                name: "女",
                data: sex0List,
                type: "line",
                itemStyle: {
                  normal: {
                    lineStyle: {
                      width: 1 //折线宽度
                    }
                  }
                }
              }
            ];
            const nameList = ["男", "女"];
            this.tableDate = { nameList, dateList };
            // 重新加载数据
            lineChart.removeAttribute("_echarts_instance_");
            this.drawLine();
          }
        });
    },
    // 报告阴阳性趋势
    selectType3(type) {
      this.active = type;
      //医院详情数据
      let motheDeils = this.chinaMapData;
      let code;

      if (this.$store.state.mapLevel == 1) {
        code = this.$getCode(this.$store.state.HospitalCode);
      } else {
        code = this.$getCode(this.$store.state.currentHospital);
      }
      code = code ? code : this.$getCode(this.$store.state.HospitalCode);
      this.axios
        .get("/stat/queryMasculineTotalByMonth.service", {
          params: {
            // currentDate: this.$moment(new Date()).format('YYYY-MM-DD'),
            startDate: this.$moment(this.timeData[0]).format("YYYYMMDD"),
            endDate: this.$moment(this.timeData[1]).format("YYYYMMDD"),
            hospitalCodeList: code
          }
        })
        .then(result => {
          if (result.success) {
            const reult = [].concat(result.data);
            this.seriesData = []; //  数组源
            // 阴数组
            let mas0Count = Array.from(
              new Set(
                reult.map(d => {
                  return d.mas0Count;
                })
              )
            );
            // 阳数组
            let mas1Count = Array.from(
              new Set(
                reult.map(d => {
                  return d.mas1Count;
                })
              )
            );
            // 日期数组
            let dateList = Array.from(
              new Set(
                reult.map(d => {
                  return d.studyDate;
                })
              )
            ).sort();
            this.seriesData = [
              {
                name: "阴",
                data: mas0Count,
                type: "line",
                itemStyle: {
                  normal: {
                    lineStyle: {
                      width: 1 //折线宽度
                    }
                  }
                }
              },
              {
                name: "阳",
                data: mas1Count,
                type: "line",
                itemStyle: {
                  normal: {
                    lineStyle: {
                      width: 1 //折线宽度
                    }
                  }
                }
              }
            ];
            const nameList = ["阴", "阳"];
            this.tableDate = { nameList, dateList };
            lineChart.removeAttribute("_echarts_instance_");
            // 重新加载数据
            this.drawLine();
          }
        });
    },
    //点击搜索
    handleFilter() {
      this.active === 10
        ? this.selectType1(10)
        : this.active === 20
        ? this.selectType2(20)
        : this.selectType3(30);
    },
    //打印图表
    Print() {
      //print(document.getElementById('lineChart'))
      document.title =
        this.active == "10"
          ? `${this.$titles}(检查量趋势)`
          : this.active == "20"
          ? `${this.$titles}(患者数量趋势)`
          : `${this.$titles}(报告阴阳性趋势)`; // 默认是第一个
      this.$print(document.getElementById("lineChart"));
      // this.$print(this.$refs.print);
    }
  }
};
</script>

<style lang="scss">
// 组件样式调整
.lineChart {
  height: 6rem;

  .el-dialog {
    background-color: rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 1px rgba(54, 241, 242, 0.3);
  }

  .el-dialog__header {
    padding: 0;
    height: 0;
    background: #122c3a;
  }

  .el-dialog__body {
    padding: 0;
    background: #040b22;
  }

  .searchBox {
    display: flex;
    background: #122c3a;
    height: 50px;
    line-height: 50px;
    font-size: 12px;

    .searchBox-item {
      width: 140px;
      cursor: pointer;
      text-align: center;
      height: 49px;
      box-shadow: 0 0 0 1px rgba(54, 241, 242, 0.2);
      color: #fff;
    }

    .active {
      background: #040b22;
      color: #38ffff;
    }
  }

  .date-picker {
    padding: 35px 0 10px 40px;

    .el-input--prefix .el-input__inner {
      color: #00eaff !important;
    }
  }

  .leo-four {
    height: 550px;
  }
}
</style>
