<template>
  <div class="StatisticsTotal3">
    <div class="StatisticsTotal-box">
      <databox></databox>
      <div class="title">
        <span class="span"></span>
        <span style="position: relative;top: -2px;" class="span2" v-if="$store.state.flag">今日</span>
        <span style="position: relative;top: -2px;" class="span2" v-else>汇总</span>
        <span style="position: relative;top: -2px;">医院排名</span>
        <img
          @click="change()"
          src="../assets/images/paimin.png"
          style="    top: 2px;
    left: 8px;cursor: pointer;position: relative;z-index: 50;"
          alt
        />
        <!-- <i
          class="leo-icon el-icon-s-fold"
          style="cursor: pointer;position: relative;z-index: 50;"
          @click="change()"
        ></i>-->
        <div class="echart-button" @click="particulars()">
          <img
            src="../assets/images/xiangqing.png"
            style="position: relative;top: 2px;left: -3px;"
            alt
          />
          <!--<i class="el-icon-menu"></i>-->
          详情
        </div>
      </div>
      <div class="seamless-box">
        <div class="scrollTableHead">
          <div class="headCellr" style="width:10%">排名</div>
          <!--class="headCell"-->
          <div class="headCellr" style="width:40%">医院名称</div>
          <div class="headCellr" style="width:25%">检查人次</div>
          <div class="headCellr" style="width:25%">文件大小</div>
        </div>
        <div class="scrollTableBody">
          <div
            class="bodyRow"
            v-for="(item,index) in $store.state.dayhData"
            :key="index"
            :class="generateClassName[index]"
          >
            <div class="bodyCell" style="width:10%">{{index+1}}</div>
            <div class="bodyCell" style="width:40%">{{item.hospitalName}}</div>
            <div class="bodyCell" style="width:25%">{{item.studyTotal}}</div>
            <div class="bodyCell" style="width:25%">{{$getSizeString(item.fileSizTotal/1024/1024)}}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 医院排名详情弹出框 -->
    <el-dialog title="医院排名详情" class="details" :visible.sync="dialogTableVisible">
      <!-- 这里面写一个时间的搜索框 -->
      <div class="date-picker">
        <button class="filter-dy" @click="handleDownload">
          <img style="width: 17px;" src="../assets/images/daochu.png" alt />
        </button>
        <el-date-picker
          v-model="timeData"
          type="daterange"
          size="mini"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          class="data-pick"
          unlink-panels
          @change="handleFilter"
        ></el-date-picker>
        
        <!-- <button class="filter-search" @click="handleFilter">
          <span class="el-icon-search"></span>
        </button> -->
      </div>

      <el-table
        border
        :data="$store.state.dayhData2"
        style="width:100%;font-size: 12px;"
        id="outTable"
        :height="500"
        :header-cell-style="{background:'#0C3043',color:'#FFFFFF'}"
      >
        <!-- v-if="$store.state.dayhData2.length > 0" -->
        <el-table-column width="120" align="center" type="index" label="序号"></el-table-column>
        <el-table-column width="150" align="center" property="hospitalName" label="医院名称"></el-table-column>
        <el-table-column width="120" align="center" property="studyTotal" label="检查量"></el-table-column>
        <el-table-column width="120" align="center" property="fileSizTotal" label="存储空间">
          <template slot-scope="scope">
            <span>{{$getSizeString(scope.row.fileSizTotal/1024/1024)}}</span>
          </template>
        </el-table-column>
        <el-table-column width="120" align="center" property="userTotal" label="患者数量"></el-table-column>
        <el-table-column width="120" align="center" property="reportTotal" label="报告数量"></el-table-column>
        <el-table-column width="120" align="center" property="examineTypeDR" label="DR"></el-table-column>
        <el-table-column width="120" align="center" property="examineTypeCT" label="CT"></el-table-column>
        <el-table-column width="120" align="center" property="examineTypeMR" label="MR"></el-table-column>
        <el-table-column width="120" align="center" property="examineTypeUS" label="US(超声)"></el-table-column>
        <el-table-column width="120" align="center" property="examineTypePS" label="PS(病理)"></el-table-column>
        <el-table-column width="120" align="center" property="examineTypeECG" label="ECG(心电)"></el-table-column>
        <el-table-column width="120" align="center" property="examineTypeES" label="ES(内镜)"></el-table-column>
      </el-table>
      <pagination
        v-show="total>0"
        :total="total"
        :page.sync="page"
        :limit.sync="dateForm.page.length"
        @pagination="particulars"
      />
    </el-dialog>
  </div>
</template>

<script>
import Databox from "./Databox";
import Pagination from "./Pagination";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import { mapActions } from "vuex";

export default {
  name: "StatisticsTotal3",
  components: { Databox, Pagination },
  data() {
    return {
      alertData: [],
      yin: "#FFD600",
      yang: "#12FFFE",
      weizhi: "#C70FFF",
      /* 分页搜索数据 */
      hospitalDetails: [],
      total: 0, // 页数
      listLoading: true,
      page: 1,
      startDate: "",
      endDate: "",
      hospitalCodeList: "",
      dataText: "", //进去页面先让字样为空
      dateForm: {
        page: {
          current: 1, // 当前页pageNum
          length: 30 // 每页显示条数pageSize
        }
      },
      //前三名颜色
      sstt: ["ss1", "ss2", "ss3"],
      generateClassName: ["ss1", "ss2", "ss3"],
      //详情弹出框
      dialogTableVisible: false,
      /* 日期选择 */
      /* 获取过去时间，并传值给现在时间 */
      getPassYearFormatDate() {
        var nowDate = new Date();
        var date = new Date(nowDate);
        date.setDate(date.getDate() - 365);
        var seperator1 = "-";
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var strDate = date.getDate();
        if (month >= 1 && month <= 9) {
          month = "0" + month;
        }
        if (strDate >= 0 && strDate <= 9) {
          strDate = "0" + strDate;
        }
        var formatDate = year + seperator1 + month + seperator1 + strDate;
        this.getNowFormatDate(formatDate);
      },
      /* 获取现在时间，并接受过去时间的值 */
      getNowFormatDate(formatDate) {
        var date = new Date();
        var seperator1 = "-";
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var strDate = date.getDate();
        if (month >= 1 && month <= 9) {
          month = "0" + month;
        }
        if (strDate >= 0 && strDate <= 9) {
          strDate = "0" + strDate;
        }
        var nowData = year + seperator1 + month + seperator1 + strDate;
        this.timeData = [formatDate, nowData]; // 默认赋值一年时间
      },
      //时间
      timeData: [],
      //导出
      exportParams: {}
      //今日汇总
      //flag: true //单位切换开关
    };
  },
  mounted() {
    this.getPassYearFormatDate();
  },
  computed: {
    //所有医院
    chinaMapData() {
      return this.$store.state.chinaMapData;
    }
  },
  watch: {
    dialogTableVisible(newVal, oldVal) {
      this.getPassYearFormatDate();
    }
  },
  methods: {
    ...mapActions(["loadHosData2", "loadHosData"]),
    // 详情弹出框
    particulars() {
      //先将变量清空
      this.dataText = "";
      this.dialogTableVisible = true;
      //医院排名详情
      this.dateForm.page.current = this.page;
      //this.flag = !this.flag;
      let code;
      if (this.$store.state.mapLevel == 1) {
        code = this.$getCode(this.$store.state.HospitalCode);
      } else {
        code = this.$getCode(this.$store.state.currentHospital);
      }
      code = code ? code : this.$getCode(this.$store.state.HospitalCode);
      this.axios
        .get("/stat/queryHospitalRankForStat.service", {
          params: {
            startDate: this.$moment(this.timeData[0]).format("YYYYMMDD"),
            endDate: this.$moment(this.timeData[1]).format("YYYYMMDD"),
            hospitalCodeList: code,
            page: {
              current: 1, // 当前页pageNum
              length: 1000 // 每页显示条数pageSize
            }
          }
        })
        .then(result => {
          if (result.success) {
            this.$store.state.dayhData2 = result.data;
          }
        });
    },
    change() {
      this.$store.state.flag = !this.$store.state.flag;
      let code;
      if (this.$store.state.mapLevel == 1) {
        code = this.$getCode(this.$store.state.HospitalCode);
      } else {
        code = this.$getCode(this.$store.state.currentHospital);
      }

      let params = {
        hospitalCodeList: code, //this.$store.state.HospitalCode.join(","),
        page: {
          current: 1, // 当前页pageNum
          length: 1000 // 每页显示条数pageSize
        }
      };

      if (this.$store.state.flag) {
        params.startDate = this.$moment(this.timeData[1]).format("YYYYMMDD");
        params.endDate = this.$moment(this.timeData[1]).format("YYYYMMDD");
      } else {
        params.startDate = "";
        params.endDate = "";
      }
      this.loadHosData(params);
    },
    //点击搜索
    handleFilter() {
      this.particulars();
      //this.selectType1(20)
    },
    //导出
    handleDownload() {
      this.downloadLoading = true;
      //医院排名详情
      let hospitDeils = this.chinaMapData;
      let code;
      if (this.$store.state.mapLevel == 1) {
        code = this.$getCode(this.$store.state.HospitalCode);
      } else {
        code = this.$getCode(this.$store.state.currentHospital);
      }
      code = code ? code : this.$getCode(this.$store.state.HospitalCode);
      let data = {};
      data = {
        startDate: this.$moment(this.timeData[0]).format("YYYYMMDD"),
        endDate: this.$moment(this.timeData[1]).format("YYYYMMDD"),
        hospitalCodeList: code //codelist
      };
      // if (this.flag) {
      //   data = {
      //     startDate: this.$moment(this.timeData[1]).format("YYYYMMDD"),
      //     endDate: this.$moment(this.timeData[1]).format("YYYYMMDD"),
      //     hospitalCodeList: code //codelist
      //   };
      // } else {
      //   data = {
      //     startDate: this.$moment(this.timeData[0]).format("YYYYMMDD"),
      //     endDate: this.$moment(this.timeData[1]).format("YYYYMMDD"),
      //     hospitalCodeList: code //codelist
      //   };
      // }
      this.axios
        .get("/stat/exportHospitalRankForStat.service", {
          params: data,
          responseType: "blob"
        })
        .then(excel => {
          const link = document.createElement("a");
          const blob = new Blob([excel], {
            type: "application/vnd.ms-excel"
          });
          link.style.display = "none";
          link.href = URL.createObjectURL(blob);
          link.setAttribute("download", "检查量统计_" + ".xlsx");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(() => {});
    }
  }
};
</script>

<style scoped lang="scss">
.StatisticsTotal3 {
  position: relative;

  .StatisticsTotal-box {
    width: 480px;
    height: 330px;
    overflow: hidden;
    position: relative;
    margin-bottom: 20px;
    opacity: 0.8;

    .title {
      width: 100%;
      height: 28px;
      line-height: 28px;
      margin-top: 36px;
      color: #00eaff;
      padding-left: 40px;
      font-size: 20px;
      font-weight: 550;

      span.span {
        display: block;
        float: left;
        width: 10px;
        height: 10px;
        background: #00eaff;
        margin-right: 10px;
        margin-top: 7px;
      }

      span.span2 {
        color: white;
      }

      i.leo-icon {
        color: white;
        margin-left: 15px;
      }

      .echart-button {
        float: right;
        width: 70px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 1px;
        border: 1px solid rgba(18, 255, 254, 1);
        color: #00eaff;
        font-size: 14px;
        margin-right: 20px;
        position: relative;
        z-index: 2;
        cursor: pointer;

        i {
          margin-right: 3px;
        }
      }
    }

    .seamless-box {
      width: 100%;
      text-align: center;

      .scrollTableHead {
        padding-top: 20px;
        height: 50px;
        line-height: 50px;
        .headCellr {
          //float: left;
          //display: flex;
          //text-align: center;
          float: left;
          text-align: center;
        }

        // .headCell {
        //   width: 25%;
        //   float: left;
        // }
      }

      .scrollTableBody {
        clear: both;
        height: 200px;
        overflow: scroll;
        position: relative;
        z-index: 60;

        .bodyRow {
          display: flex;
          padding: 8px 0;

          // .bodyCell {
          //   width: 25%;
          // }
        }

        .ss1 {
          color: #ffd600;
        }

        .ss2 {
          color: #00eaff;
        }

        .ss3 {
          color: #c70fff;
        }
      }
    }
  }

  .StatisticsTotal-box::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 199%;
    height: 199%;
    border: 1px solid #61d2f7;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scale(0.5, 0.5);
    transform: scale(0.5, 0.5);
    z-index: 1;
  }

  ::-webkit-scrollbar {
    /*滚动条整体部分，其中的属性有width,height,background,border等（就和一个块级元素一样）（位置1）*/
    width: 8px;
    height: 8px;
    /*background: red;*/
    /*display: none; !* 为了图省事，建议直接不显示滚动条 *!*/
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    height: 3px !important;
    background-color: #024e62;
    background-image: -webkit-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.2) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0.2) 75%,
      transparent 75%,
      transparent
    );
    /*   width: 30px; */
    /* background: #024E62; */
  }

  .el-dialog__wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    margin: 0;
  }

  .el-table__body,
  .el-table__footer,
  .el-table__header {
    width: auto;
  }
}
</style>
<style lang="scss">
.el-table th {
  display: table-cell !important;
}
.el-table--scrollable-x .el-table__body-wrapper {
  overflow-x: auto;
  background: #081e33;
}
.el-input__icon {
  height: 100%;
  width: 25px;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  line-height: 47px;
}

.el-dialog {
  width: 70%;
  /* height: 3.8rem; */
  background: #040b22;
  box-shadow: 0 0 0 1px rgba(54, 241, 242, 0.3);
}

.date-picker {
  margin-bottom: 35px;
  height: 40px;
  line-height: 40px;
  position: relative;
  padding: 40px 0;
}

.el-dialog__header {
  display: flex;
  padding: 0;
  background: #122c3a;
  height: 7%;
  opacity: 0.8;
}

.el-dialog__title {
  color: #12fffe;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 0.27rem;
  padding-left: 50px;
}

.el-dialog__body {
  background: #040b22;
  padding: 0 50px 50px 50px;
}

.el-table tr {
  background: #081e33;
  color: #e5e5e7;
}

.el-table--border:after,
.el-table--group:after,
.el-table:before {
  background-color: #096372;
}

.el-table--border,
.el-table--group {
  border-color: #096372;
  opacity: 0.8;
}

.el-table td,
.el-table th.is-leaf {
  border-bottom: 1px solid #096372;
}

.el-table--border th,
.el-table--border th.gutter:last-of-type {
  border-bottom: 1px solid #096372;
}

.el-table--border td,
.el-table--border th {
  border-right: 1px solid #096372;
}

.el-table th.gutter {
  display: table-cell !important;
}

.el-dialog__headerbtn {
  position: absolute;
  top: 5px;
  z-index: 9999;
  width: 3%;
  height: 5%;
  line-height: 5%;
  /*  background: url(../assets/images/fanhui.png) no-repeat; */
}

.el-dialog__close {
  color: #fff;
  /* display: none; */
}

.el-input--prefix .el-input__inner {
  height: 30px;
  line-height: 30px;
  background: #081e33;
  border-radius: 1px;
  border: none;
  opacity: 0.8;
  color: #00eaff !important;
  box-shadow: 0 0 0 1px rgba(54, 241, 242, 0.4);
}

.el-input__prefix,
.el-input__suffix {
  position: absolute;
  top: -4px;
}

.filter-search {
  width: 40px;
  padding: 5px 0 5px 0;
  margin-left: 20px;
  background: none;
  border: 1px solid rgb(18, 255, 254);
  border-radius: 1px;
  color: #00eaff;
  cursor: pointer;
}

.filter-dy {
  width: 40px;
  height: 0.18rem;
  padding: 5px 0 3px 0;
  margin-right: 20px;
  background: none;
  border: 1px solid rgba(176, 255, 0, 1);
  border-radius: 1px;
  position: relative;
  top: 2px;
  cursor: pointer;
}
.el-date-table td.current:not(.disabled) span {
  color: #fff !important;
  background-color: #45698e !important;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #12fffe !important;
  color: #040b22 !important;
}
.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background-color: #092648;
  color: #fff;
}

.el-table__body tr:hover > td {
  background-color: #45698e !important;
}

.el-table__body tr.current-row > td {
  background-color: #45698e !important;
}
.el-table__empty-block {
  width: 0;
}
.el-table__empty-text {
  font-size: 35px !important;
}
.el-pagination__total {
  margin-right: 10px;
  font-weight: 400;
  color: #fff;
}
.el-pagination__jump {
  margin-left: 24px;
  font-weight: 400;
  color: #fff;
}
.el-picker-panel__icon-btn {
  font-size: 12px;
  color: #bfcacc;
  border: 0;
  background: 0 0;
  cursor: pointer;
  outline: 0;
  margin-top: 8px;
}
</style>
